// extracted by mini-css-extract-plugin
export var BtnPrimary = "FeaturedFellowCard-module--BtnPrimary--e8f16";
export var BtnPrimaryAlt = "FeaturedFellowCard-module--BtnPrimaryAlt--deeac";
export var Container = "FeaturedFellowCard-module--Container--89735";
export var FeaturedFellowCard = "FeaturedFellowCard-module--FeaturedFellowCard--83740";
export var FlexWrap = "FeaturedFellowCard-module--FlexWrap--873db";
export var H1 = "FeaturedFellowCard-module--H1--e262d";
export var H2 = "FeaturedFellowCard-module--H2--42fc3";
export var H3 = "FeaturedFellowCard-module--H3--3feea";
export var H4 = "FeaturedFellowCard-module--H4--73df0";
export var H5 = "FeaturedFellowCard-module--H5--61bba";
export var H6 = "FeaturedFellowCard-module--H6--23db6";
export var airTableImg = "FeaturedFellowCard-module--airTableImg--d0bb8";
export var circleText = "FeaturedFellowCard-module--circleText--51459";
export var cssMohuvpDummyInputDummyInput = "FeaturedFellowCard-module--css-mohuvp-dummyInput-DummyInput--2bf38";
export var donate = "FeaturedFellowCard-module--donate--f5626";
export var featuredContainer = "FeaturedFellowCard-module--featuredContainer--2ed02";
export var fellowInfo = "FeaturedFellowCard-module--fellowInfo--f553a";
export var imageBox = "FeaturedFellowCard-module--imageBox--cd7a7";
export var location = "FeaturedFellowCard-module--location--bd9f2";
export var name = "FeaturedFellowCard-module--name--03dbc";
export var next = "FeaturedFellowCard-module--next--cb70d";
export var pagination = "FeaturedFellowCard-module--pagination--2d8c4";
export var pagination__link = "FeaturedFellowCard-module--pagination__link--e240c";
export var pagination__linkActive = "FeaturedFellowCard-module--pagination__link--active--fc4e7";
export var pagination__linkDisabled = "FeaturedFellowCard-module--pagination__link--disabled--77c95";
export var previous = "FeaturedFellowCard-module--previous--fb624";
export var project = "FeaturedFellowCard-module--project--d29df";
export var reactSelect__control = "FeaturedFellowCard-module--react-select__control--e42b1";
export var reactSelect__indicator = "FeaturedFellowCard-module--react-select__indicator--3e969";
export var reactSelect__multiValue = "FeaturedFellowCard-module--react-select__multi-value--52b30";
export var reactSelect__multiValue__remove = "FeaturedFellowCard-module--react-select__multi-value__remove--db17a";
export var reactSelect__option = "FeaturedFellowCard-module--react-select__option--1e7fb";
export var reactSortContainer = "FeaturedFellowCard-module--react-sort-container--99adb";
export var reactSort__control = "FeaturedFellowCard-module--react-sort__control--25598";
export var reactSort__indicator = "FeaturedFellowCard-module--react-sort__indicator--8119a";
export var reactSort__indicatorSeparator = "FeaturedFellowCard-module--react-sort__indicator-separator--c359e";
export var reactSort__placeholder = "FeaturedFellowCard-module--react-sort__placeholder--29854";
export var tagline = "FeaturedFellowCard-module--tagline--0e162";
export var textBox = "FeaturedFellowCard-module--textBox--2e528";
export var videoContainer = "FeaturedFellowCard-module--videoContainer--2e8fa";
export var white = "FeaturedFellowCard-module--white--7e7a7";