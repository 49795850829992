import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Container from "../components/Container"
import TextBox from '../components/TextBox';

export const CenterText = ({ slice }) => {
  const bgStyle = slice.primary.background_image?.url && {
    backgroundImage: "url(" + slice.primary.background_image?.url + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };
  return (
    <section style={bgStyle}>
      <Container>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <TextBox
            width={820}
            tAlign={"center"}
          >
            <PrismicRichText field={slice.primary.text?.richText}/>
          </TextBox>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCenterText on PrismicHomepageDataBodyCenterText {
    id
    primary {
      text {
        richText
      }
      background_image {
        url
      }
    }
  }
  fragment PageDataBodyCenterText on PrismicPageDataBodyCenterText {
    id
    primary {
      text {
        richText
      }
      background_image {
        url
      }
    }
  }
`
