import React, {useEffect, useState} from 'react'
import Container from '../components/Container'
import ReactPaginate from 'react-paginate'
import FellowCard from './FellowCard'
import Select from 'react-select'

import * as s from '../styles/components/ListingArea.module.scss'
import { useFellows } from '../utils/fellowsProvider'

const ListingArea = (props) => {
  const {
    sort_placeholder,
    lang,
    alphabetical_label,
    fellow_project,
    fellow_btn,
    prev,
    next
  } = props

  const { fellows, handleSort} = useFellows()

  const itemsPerPage = 9
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(fellows.length / itemsPerPage)

  const handleSelectedOption = (newValue) => {
    handleSort(newValue.value)
  }
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % fellows.length;
    setItemOffset(newOffset);
    const element = document.getElementById("fellowCards")
    element.scrollIntoView();
  }
  const options = [
    {value: 0, label: `${alphabetical_label}: A-Z`},
    {value: 1, label: `${alphabetical_label}: Z-A`},
  ]
  return (
    <section className={s.FellowListing} id="fellowCards">
      <Container>
        <div>
         <Select
          onChange={handleSelectedOption}
          options={options}
          isClearable={false}
          isSearchable={false}
          className="react-sort-container"
          classNamePrefix="react-sort"
          placeholder={sort_placeholder}
        />
        </div>

        <div className={s.Grid} style={{justifyContent: 'space-between'}}>
            {
              fellows.slice(itemOffset,endOffset).map(fellow => (
                <FellowCard
                  fellow={fellow}
                  project_label={fellow_project}
                  fellow_btn={fellow_btn}
                  lang={lang}
                  width="auto"
                />
              ))
            }
          </div>
        
        
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
         
          renderOnZeroPageCount={null}
          previousLabel={`← ${prev}`}
          nextLabel={`${next} →`}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </Container>
    </section>
  )
}
export default ListingArea