import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Container from "../components/Container"
import TextBox from '../components/TextBox'
import ImageBox from '../components/ImageBox';
import { Card, FlexWrap,CardlessContainer } from '../styles/slices/card_row.module.scss'

export const CardlessRow = ({ slice }) => {
  return (
    <section className={CardlessContainer}>
      <Container>
        <div style={{display:'flex',justifyContent:'center'}}>
          <TextBox
            width={695}
            tAlign={"center"}
          >
            <PrismicRichText field={slice.primary.header_rt?.richText}/>
          </TextBox>
        </div>
        <div className={FlexWrap}>
          {slice.items.map((item,index) => (
            <div className={Card}>
               <ImageBox
                image={item.image}
                borderRadius={0}
              />
              <PrismicRichText field={item.copy_rt?.richText}/>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyCardlessRow on PrismicPageDataBodyCardlessRow {
    id
    primary {
      header_rt {
        richText
      }
    }
    items {
      image {
        gatsbyImageData
        alt
      }
      copy_rt {
        richText
      }
    }
  }
`
