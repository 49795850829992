import * as React from 'react';
import { graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Nav, NavItem, NavItemActive } from '../styles/slices/page_nav.module.scss'

export const PageNav = ({ slice }) => {
  
  const length = slice.items.length;
  const [active, setActive] = React.useState(0);
  const [prevHeight, setPrevHeight] = React.useState();
  const [curHeight, setCurHeight] = React.useState();
  const [nextHeight, setNextHeight] = React.useState();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setCurHeight(position);
  };
  function handleHeight(id){
    const d = document.getElementById(id);
    return d.offsetTop;
  }
  React.useEffect(() => {
    setPrevHeight(0);
    setNextHeight(handleHeight(slice.items[active].anchor_id));
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  React.useEffect(() => {
    if(curHeight >= nextHeight){
      setActive(active + 1);      
    } else if(curHeight < prevHeight){
      setActive(active - 1);
    }
  }, [curHeight]);

  React.useEffect(() => {
    const i = active;
    if(active === 0){
      setPrevHeight(0);
      setNextHeight(handleHeight(slice.items[i].anchor_id));
    }else if(active === length) {
      setPrevHeight(handleHeight(slice.items[i-1].anchor_id));
      setNextHeight(999999);
    } else {
      setPrevHeight(handleHeight(slice.items[i - 1].anchor_id));
      setNextHeight(handleHeight(slice.items[i].anchor_id));
    }
  }, [active]);

  return (
    <>
      <div style={{paddingTop: 60, background: 'white', maxWidth: 1640, margin:'0 auto -40px' }} id="PageNav"/>
      <div className={Nav}>

        {slice.items.map((item,index) => (
          <AnchorLink
            className={index === active - 1 ? NavItemActive : NavItem} 
            href={`#${item.anchor_id}`} 
            offset={item.offset || 0}
          >
            {item.link_label}
          </AnchorLink>
        ))}
      </div>
      <div style={{paddingBottom: 60, background: 'white', maxWidth: 1640, margin:'-40px auto 0'}}/>
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyPageNav on PrismicPageDataBodyPageNav {
    id
    items {
      anchor_id
      link_label
      offset
    }
  }
`
