import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { FlexWrap, Form, ring, Label } from '../styles/slices/newsletter.module.scss'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Container from "../components/Container"
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox'

export const Newsletter = ({ slice }) => {
  const [email, setEmail] = React.useState("");
  const [result, setResult] = React.useState(null);

  const handleSubmit = async e => {
    e.preventDefault();
    const Result = await addToMailchimp(email);
    setResult(Result);
  }
  const handleChange = e => {
    setEmail(event.target.value);
  }
  return (
    <section style={{background: "#20063B"}}>
      <Container>
        <div className={FlexWrap}>
          <TextBox
            width={695}
            tAlign={"left"}
          >
            <PrismicRichText field={slice.primary.text?.richText}/>
            <form onSubmit={handleSubmit}>
              <label className={Label} for="email">{slice.primary.email_label || "Email Address:"}</label>
              <div className={Form}>
                <input id="email" type="email" name="email" placeholder={slice.primary.email_placeholder} onChange={handleChange}/>
                <span className={ring}><input type="submit" value={slice.primary.button_label || "Submit"} className="BtnPrimary"/></span>
              </div>  
            </form>
            {
              result?.result == 'success' ? (
                <p>{result?.msg}</p>
              ) :
              result?.result == 'error' ? (
                <p>{result?.msg}</p>
              ) :
              null
            }
          </TextBox>
          <ImageBox
            image={slice.primary.image}
            borderRadius={20}
          />
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyNewsletter on PrismicHomepageDataBodyNewsletter {
    id
    primary {
      button_label
      email_label
      email_placeholder
      image {
        dimensions {
          width
          height
        }
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
`
