import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText,PrismicLink } from '@prismicio/react';
import Container from "../components/Container";
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox';
import { navigate } from 'gatsby-link'
import { FlexWrap, Form, ring, required } from '../styles/slices/contact_form.module.scss'
import CircleText from '../components/CircleText'

export const ContactForm = ({ slice,context }) => {
  const thank_you = context.lang.slice(0,2) == "fr" ? "/thankYou-fr/" : "/thankYou/";
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  }
  const [state, setState] = React.useState({})
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/en/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <section >
      <Container>
        <div className={FlexWrap}>
          <TextBox width={560}>
            <PrismicRichText field={slice.primary.text?.richText}/>
            <form 
              className={Form}
              method="POST" 
              netlify-honeypot="bot-field" 
              data-netlify="true" 
              name="contact"
              onSubmit={handleSubmit}
              action={thank_you}
            >
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <input type="hidden" name="form-name" value="contact" onChange={handleChange}/>
              <label for="name" className={required}>{slice.primary.name_label}</label>
              <input type="text" id="name" name="name" required onChange={handleChange}/>
              <label for="email" className={required}>{slice.primary.email_label}</label>
              <input type="email" id="email" name="email" required onChange={handleChange}/>
              <label for="message">{slice.primary.message_label}</label>
              <textarea rows="8" id="message" name="message" style={{resize:"none"}} onChange={handleChange}/>
              <label className={ring}>
                <input type="submit" value={slice.primary.button_label} className="BtnPrimary" onChange={handleChange}/>
              </label>
            </form>
          </TextBox>
          <ImageBox
            image={slice.primary.image}
            width={675}
          />
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContactForm on PrismicPageDataBodyContactForm {
    id
    primary {
      button_label
      email_label
      image {
        gatsbyImageData
        alt 
        dimensions {
          width
          height
        }
      }
      message_label
      name_label
      text {
        richText
      }
    }
  }
`
