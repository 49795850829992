import React, {useEffect, useState, useContext} from 'react'
import Container from '../components/Container'
import TextBox from './TextBox'

import { 
  CarouselProvider,
  Slide, 
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup, 
} from 'pure-react-carousel'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import FellowCard from './FellowCard'


const FeatureArea = (props) => {
  const {
    title,
    featured,
  } = props
  const total_slides = featured?.length

  return (
    <>
    { featured?.length > 0 &&
    
      <section style={{background: '#20063B', marginBottom: '45px'}}>
      <Container>
        <TextBox width={750}>
          <h2>{title}</h2>
          
        </TextBox>
        <CarouselProvider
          naturalSlideWidth={429}
          totalSlides={total_slides}
          visibleSlides={3}
          infinite={false}
          step={3}
          isIntrinsicHeight={true}
        >
          <div >
            <ButtonBack  ><FaChevronLeft aria-label="left arrow" /></ButtonBack>
            <ButtonNext  ><FaChevronRight aria-label="right arrow" /></ButtonNext>
          </div>  
          <Slider 
            classNameTray="slider-tray"
            classNameTrayWrap="slider-tray-wrap"
          >
            {featured?.map((fellow,index) => (
              <Slide 
                index={index} 
                key={`carousel: ${index}`} 
                tabIndex={-1} 
                classNameHidden="hidden-slide"
              >
                <FellowCard
                  fellow={fellow}
                />
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </Container>
    </section>
    }
    </>
  )
}
export default FeatureArea