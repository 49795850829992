import React from 'react'
import { PrismicRichText} from '@prismicio/react'
import Container from './Container'
import TextBox from './TextBox'
import FeaturedFellowCard from './FeaturedFellowCard'
import * as s from '../styles/components/FeaturedFellowCard.module.scss'

const FeaturedFellows= ({title, featuredFellowsLabel, description, btnLabel, featuredFellows, lang, searchAllFellowsLink}) => {

  return (
    <section >
      <Container>
        <div className="FlexWrap">
          <div>
            <TextBox width={'auto'}>
              <PrismicRichText field={title?.richText}/>
              <p>{description}</p>
              <a href={searchAllFellowsLink}>
              <button href={searchAllFellowsLink} className="BtnPrimary">{btnLabel}</button>
              </a>
            </TextBox>
          </div>
          <div className={s.featuredContainer}>
            <p style={{textTransform:'uppercase', padding:'20px 0', fontWeight:'bold'}}>{featuredFellowsLabel}</p>
            <div>
              {featuredFellows.map((fellow, index)=><FeaturedFellowCard key={index} fellow={fellow.data} lang={lang} />)}
            </div> 
          </div>
        </div>
      </Container>
    </section>
  )
}
export default FeaturedFellows
