import * as React from 'react';
import { graphql } from 'gatsby';

export const AnchorPoint = ({ slice }) => {
  return (
    <div id={slice.primary.anchor_id}/>
  )
}

export const query = graphql`
  fragment PageDataBodyAnchorPoint on PrismicPageDataBodyAnchorPoint {
    id
    primary {
      anchor_id
    }
  }
`
