// extracted by mini-css-extract-plugin
export var BtnPrimary = "IMTSection-module--BtnPrimary--c7813";
export var BtnPrimaryAlt = "IMTSection-module--BtnPrimaryAlt--6894b";
export var CenterAlign = "IMTSection-module--CenterAlign--bd492";
export var Container = "IMTSection-module--Container--d31ab";
export var FlexWrap = "IMTSection-module--FlexWrap--1e92b";
export var H1 = "IMTSection-module--H1--8b132";
export var H2 = "IMTSection-module--H2--ab38d";
export var H3 = "IMTSection-module--H3--d12e2";
export var H4 = "IMTSection-module--H4--d15f7";
export var H5 = "IMTSection-module--H5--6f3ab";
export var H6 = "IMTSection-module--H6--4fb44";
export var IMTSection = "IMTSection-module--IMTSection--a6d72";
export var airTableImg = "IMTSection-module--airTableImg--3e653";
export var circleText = "IMTSection-module--circleText--fd57e";
export var cssMohuvpDummyInputDummyInput = "IMTSection-module--css-mohuvp-dummyInput-DummyInput--0636c";
export var donate = "IMTSection-module--donate--011f9";
export var imageBox = "IMTSection-module--imageBox--26561";
export var next = "IMTSection-module--next--03e62";
export var pagination = "IMTSection-module--pagination--483fe";
export var pagination__link = "IMTSection-module--pagination__link--4df0c";
export var pagination__linkActive = "IMTSection-module--pagination__link--active--bd69c";
export var pagination__linkDisabled = "IMTSection-module--pagination__link--disabled--e11c6";
export var previous = "IMTSection-module--previous--8bbf8";
export var reactSelect__control = "IMTSection-module--react-select__control--42121";
export var reactSelect__indicator = "IMTSection-module--react-select__indicator--38eb6";
export var reactSelect__multiValue = "IMTSection-module--react-select__multi-value--76a48";
export var reactSelect__multiValue__remove = "IMTSection-module--react-select__multi-value__remove--e771c";
export var reactSelect__option = "IMTSection-module--react-select__option--ea0c4";
export var reactSortContainer = "IMTSection-module--react-sort-container--383f2";
export var reactSort__control = "IMTSection-module--react-sort__control--6ab0a";
export var reactSort__indicator = "IMTSection-module--react-sort__indicator--2f87d";
export var reactSort__indicatorSeparator = "IMTSection-module--react-sort__indicator-separator--b3b22";
export var reactSort__placeholder = "IMTSection-module--react-sort__placeholder--eb561";
export var tagline = "IMTSection-module--tagline--beeca";
export var textBox = "IMTSection-module--textBox--b179a";
export var videoContainer = "IMTSection-module--videoContainer--73ff5";
export var white = "IMTSection-module--white--c3e17";