// extracted by mini-css-extract-plugin
export var BtnPrimary = "tabs-module--BtnPrimary--3f8d1";
export var BtnPrimaryAlt = "tabs-module--BtnPrimaryAlt--e2235";
export var Container = "tabs-module--Container--bbc7b";
export var FlexWrap = "tabs-module--FlexWrap--1990e";
export var H1 = "tabs-module--H1--44fbb";
export var H2 = "tabs-module--H2--9d7bc";
export var H3 = "tabs-module--H3--fe6eb";
export var H4 = "tabs-module--H4--71fd9";
export var H5 = "tabs-module--H5--93ff4";
export var H6 = "tabs-module--H6--34493";
export var TabLabels = "tabs-module--TabLabels--5969d";
export var airTableImg = "tabs-module--airTableImg--87483";
export var circleText = "tabs-module--circleText--38d7d";
export var cssMohuvpDummyInputDummyInput = "tabs-module--css-mohuvp-dummyInput-DummyInput--d78c9";
export var donate = "tabs-module--donate--fe5e4";
export var imageBox = "tabs-module--imageBox--b5fce";
export var next = "tabs-module--next--c8f9e";
export var pagination = "tabs-module--pagination--9559d";
export var pagination__link = "tabs-module--pagination__link--d77fb";
export var pagination__linkActive = "tabs-module--pagination__link--active--850ae";
export var pagination__linkDisabled = "tabs-module--pagination__link--disabled--3238a";
export var previous = "tabs-module--previous--1a3ba";
export var reactSelect__control = "tabs-module--react-select__control--589c3";
export var reactSelect__indicator = "tabs-module--react-select__indicator--6ae5b";
export var reactSelect__multiValue = "tabs-module--react-select__multi-value--afe73";
export var reactSelect__multiValue__remove = "tabs-module--react-select__multi-value__remove--7a6ed";
export var reactSelect__option = "tabs-module--react-select__option--7d8a2";
export var reactSortContainer = "tabs-module--react-sort-container--733a1";
export var reactSort__control = "tabs-module--react-sort__control--7cef1";
export var reactSort__indicator = "tabs-module--react-sort__indicator--62c0b";
export var reactSort__indicatorSeparator = "tabs-module--react-sort__indicator-separator--3bfbe";
export var reactSort__placeholder = "tabs-module--react-sort__placeholder--7419f";
export var tab = "tabs-module--tab--b1753";
export var tagline = "tabs-module--tagline--228d1";
export var textBox = "tabs-module--textBox--2bd54";
export var videoContainer = "tabs-module--videoContainer--7a40c";
export var white = "tabs-module--white--2e6f1";