import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Container from "../components/Container"
import TextBox from '../components/TextBox'
import ImageBox from '../components/ImageBox'
import {FlexWrap} from '../styles/slices/text_image.module.scss'

export const AlternatingTextImage = ({ slice }) => {
  const side = slice.primary.first_image_side ? 1 : 0;

  const Text = ({item}) => (
    <TextBox width={610}>
      <PrismicRichText field={item.text?.richText}/>
      <PrismicLink
        href={item.button_link?.url}
        className={"BtnPrimary"}
      >
        {item.button_label}
      </PrismicLink>
    </TextBox>
  );
  const Image = ({item}) =>{
    return(
      <ImageBox
        image={item.image}
        borderRadius={20}
    />
    )
  };
  return (
    <section>
      <Container>
        {slice.items.map((item,index) => (
          <div className={FlexWrap} style={{marginBottom: 60}}>
            {
              index % 2 === side ? (  
                <>
                  <Image
                    item={item}
                  />
                  <Text
                    item={item}
                  />
                </>  
              ) : (
                <>
                  <Text
                    item={item}
                  />
                  <Image
                    item={item}
                  />
                </> 
              )
            }
          </div>
        ))}    
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyAlternatingTextImage on PrismicHomepageDataBodyAlternatingTextImage {
    id
    primary {
      first_image_side
    }
    items {
      button_label
      button_link {
        url
      }
      image {
        dimensions {
          width
          height
        }
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
  fragment PageDataBodyAlternatingTextImage on PrismicPageDataBodyAlternatingTextImage {
    id
    primary {
      first_image_side
    }
    items {
      button_label
      button_link {
        url
      }
      image {
        dimensions {
          width
          height
        }
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
  }
`
