// extracted by mini-css-extract-plugin
export var BtnPrimary = "ProjectInfoSection-module--BtnPrimary--488ec";
export var BtnPrimaryAlt = "ProjectInfoSection-module--BtnPrimaryAlt--5419e";
export var Container = "ProjectInfoSection-module--Container--a29bf";
export var FlexWrap = "ProjectInfoSection-module--FlexWrap--2ff5d";
export var H1 = "ProjectInfoSection-module--H1--772c2";
export var H2 = "ProjectInfoSection-module--H2--732c0";
export var H3 = "ProjectInfoSection-module--H3--bfe90";
export var H4 = "ProjectInfoSection-module--H4--2c8e5";
export var H5 = "ProjectInfoSection-module--H5--a6659";
export var H6 = "ProjectInfoSection-module--H6--56ac2";
export var ProjectInfoSection = "ProjectInfoSection-module--ProjectInfoSection--1cfd0";
export var airTableImg = "ProjectInfoSection-module--airTableImg--43a24";
export var circleText = "ProjectInfoSection-module--circleText--e5d2d";
export var cssMohuvpDummyInputDummyInput = "ProjectInfoSection-module--css-mohuvp-dummyInput-DummyInput--4b0b9";
export var donate = "ProjectInfoSection-module--donate--1f660";
export var imageBox = "ProjectInfoSection-module--imageBox--f55ad";
export var next = "ProjectInfoSection-module--next--b2509";
export var pagination = "ProjectInfoSection-module--pagination--ba0fd";
export var pagination__link = "ProjectInfoSection-module--pagination__link--2406d";
export var pagination__linkActive = "ProjectInfoSection-module--pagination__link--active--1e187";
export var pagination__linkDisabled = "ProjectInfoSection-module--pagination__link--disabled--e5dc0";
export var previous = "ProjectInfoSection-module--previous--cb9d4";
export var reactSelect__control = "ProjectInfoSection-module--react-select__control--75f6e";
export var reactSelect__indicator = "ProjectInfoSection-module--react-select__indicator--7b303";
export var reactSelect__multiValue = "ProjectInfoSection-module--react-select__multi-value--62d17";
export var reactSelect__multiValue__remove = "ProjectInfoSection-module--react-select__multi-value__remove--4440d";
export var reactSelect__option = "ProjectInfoSection-module--react-select__option--2b65d";
export var reactSortContainer = "ProjectInfoSection-module--react-sort-container--4969b";
export var reactSort__control = "ProjectInfoSection-module--react-sort__control--319cf";
export var reactSort__indicator = "ProjectInfoSection-module--react-sort__indicator--79d41";
export var reactSort__indicatorSeparator = "ProjectInfoSection-module--react-sort__indicator-separator--94873";
export var reactSort__placeholder = "ProjectInfoSection-module--react-sort__placeholder--7db8b";
export var socials = "ProjectInfoSection-module--socials--e90ca";
export var tagline = "ProjectInfoSection-module--tagline--c38c4";
export var textBox = "ProjectInfoSection-module--textBox--d8992";
export var videoContainer = "ProjectInfoSection-module--videoContainer--37752";
export var website = "ProjectInfoSection-module--website--27dad";
export var white = "ProjectInfoSection-module--white--0ac75";