import * as React from 'react'
import Container from '../components/Container'
import TextBox from './TextBox';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as s from '../styles/components/AboutProjectSection.module.scss'
import { Communities, SDGs } from '../utils/constants';

const AboutProjectSection = (props) => {
  const {
    title,
    communities,
    communitiesInfo,
    lang,
    goals,
    goalsInfo,

  } = props
  const otherLang = lang == 'en' ? 'fr' : 'en'

  return (
    <section className={s.AboutProjectSection}>
      <Container>
        <TextBox tAlign='center'>
          <h2>{title}</h2>
        </TextBox>
        <div className={s.FlexWrap}>
          <div className={s.communities}>
            <div className={s.imgContainer}>
              <GatsbyImage
                image={communitiesInfo.icon?.gatsbyImageData}
                alt=""
              />
            </div>
            
            <h3>{communitiesInfo.header}</h3>
            <p>{communitiesInfo.description}</p>
            <ul>{communities?.map((com,i) => {
              const comIndex = Communities[lang].indexOf(com) != -1 ? Communities[lang].indexOf(com) : Communities[otherLang].indexOf(com)
              return(
              <li key={i}>
                {Communities[lang][comIndex]}
              </li>
            )})}</ul>
          </div>
          <div className={s.un}>
            <div className={s.imgContainer}>
              <GatsbyImage
                image={goalsInfo.icon?.gatsbyImageData}
                alt=""
              />
            </div>
            <h3>{goalsInfo.header}</h3>
            <p>{goalsInfo.description}</p>
            <ul>{goals?.map((goal,i) => {
              
              const sdgIndex = SDGs[lang].indexOf(goal) != -1 ? SDGs[lang].indexOf(goal) : SDGs[otherLang].indexOf(goal)
              return(
              <li key={i}>
                {SDGs[lang][sdgIndex]}
              </li>
            )})}</ul>
          </div>
        </div>
      </Container> 
    </section>
  )
}
export default AboutProjectSection;