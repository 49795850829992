// extracted by mini-css-extract-plugin
export var BtnPrimary = "SDGFilter-module--BtnPrimary--b03f4";
export var BtnPrimaryAlt = "SDGFilter-module--BtnPrimaryAlt--02cc7";
export var Container = "SDGFilter-module--Container--91444";
export var FlexWrap = "SDGFilter-module--FlexWrap--3f4d3";
export var H1 = "SDGFilter-module--H1--732bc";
export var H2 = "SDGFilter-module--H2--ff8d6";
export var H3 = "SDGFilter-module--H3--1ce7d";
export var H4 = "SDGFilter-module--H4--cc4ff";
export var H5 = "SDGFilter-module--H5--8c0d3";
export var H6 = "SDGFilter-module--H6--19aef";
export var SDGIcon = "SDGFilter-module--SDGIcon--5ae87";
export var SDGIconActive = "SDGFilter-module--SDGIconActive--a4c3f";
export var airTableImg = "SDGFilter-module--airTableImg--d34fc";
export var carouselControls = "SDGFilter-module--carouselControls--d9258";
export var carouselControlsContainer = "SDGFilter-module--carouselControlsContainer--06688";
export var circleText = "SDGFilter-module--circleText--7c652";
export var cssMohuvpDummyInputDummyInput = "SDGFilter-module--css-mohuvp-dummyInput-DummyInput--accb1";
export var donate = "SDGFilter-module--donate--5dd19";
export var fa = "SDGFilter-module--fa--09227";
export var imageBox = "SDGFilter-module--imageBox--d11e0";
export var next = "SDGFilter-module--next--af2d3";
export var pagination = "SDGFilter-module--pagination--2b96a";
export var pagination__link = "SDGFilter-module--pagination__link--99099";
export var pagination__linkActive = "SDGFilter-module--pagination__link--active--8f4b8";
export var pagination__linkDisabled = "SDGFilter-module--pagination__link--disabled--7b0eb";
export var previous = "SDGFilter-module--previous--a636b";
export var prismicLink = "SDGFilter-module--prismicLink--93c70";
export var reactSelect__control = "SDGFilter-module--react-select__control--ed97f";
export var reactSelect__indicator = "SDGFilter-module--react-select__indicator--7e435";
export var reactSelect__multiValue = "SDGFilter-module--react-select__multi-value--35203";
export var reactSelect__multiValue__remove = "SDGFilter-module--react-select__multi-value__remove--cf3be";
export var reactSelect__option = "SDGFilter-module--react-select__option--e1a92";
export var reactSortContainer = "SDGFilter-module--react-sort-container--2e573";
export var reactSort__control = "SDGFilter-module--react-sort__control--9b3dd";
export var reactSort__indicator = "SDGFilter-module--react-sort__indicator--a6509";
export var reactSort__indicatorSeparator = "SDGFilter-module--react-sort__indicator-separator--ba893";
export var reactSort__placeholder = "SDGFilter-module--react-sort__placeholder--12cc7";
export var sdgIconButton = "SDGFilter-module--sdgIconButton--d2dc5";
export var sdgTitleSection = "SDGFilter-module--sdgTitleSection--a341f";
export var section = "SDGFilter-module--section--e4e3a";
export var sectionTitle = "SDGFilter-module--sectionTitle--3bc9c";
export var tagline = "SDGFilter-module--tagline--3d0a2";
export var textBox = "SDGFilter-module--textBox--99446";
export var videoContainer = "SDGFilter-module--videoContainer--ca3eb";
export var white = "SDGFilter-module--white--18670";