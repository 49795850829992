import { AlternatingTextImage } from "./alternating_text_image";
import { CardRow } from "./card_row";
import { CenterText } from "./center_text";
import { Newsletter } from "./newsletter";
import { TextImage } from "./text_image";
import { CardLinks } from "./card_links";
import { ContactForm } from "./contact_form";
import { Faq } from "./faq";
import { TabSection } from "./tabs";
import { Carousel } from "./carousel";
import { ContentArea } from "./content_area";
import { AnchorPoint } from "./anchor_point";
import { PageNav } from "./page_nav";
import { CardlessRow } from "./cardless_row";
import { ImageStat } from "./image_stat";
import { CTA } from './cta';
export const components = {
  alternating_text_image: AlternatingTextImage,
  card_row: CardRow,
  center_text: CenterText,
  text_image: TextImage,
  newsletter: Newsletter,
  card_links: CardLinks,
  contact_form: ContactForm,
  faq: Faq,
  tabs: TabSection,
  carousel: Carousel,
  content_area: ContentArea,
  anchor_point: AnchorPoint,
  page_nav: PageNav,
  cta: CTA,
  cardless_row: CardlessRow,
  image_stat: ImageStat,
}
