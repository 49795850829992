import React, { useState, useEffect, useContext, createContext } from 'react'

const fellowsHubContext = createContext()
export const useFellowsHub = () => useContext(fellowsHubContext)

export function FellowsHubProvider({ fellows, children }) {
    const fel = useFellowsHubProvider(fellows)
    return <fellowsHubContext.Provider value={fel}>{children}</fellowsHubContext.Provider>
  }


  function useFellowsHubProvider(fel) {
    const [fellows, setFellows] = useState(fel)
    const [selectedSDGId, setSelectedSDGId] = useState(null)

    useEffect(() => {
      if(!selectedSDGId){
        return;
      } 

      const filtered = fel.filter(({data})=>data.SDGs?.includes(selectedSDGId))

      setFellows(filtered)
    }, [selectedSDGId])
  
    return {
      fellows,
      selectedSDGId,
      setSelectedSDGId,
    }
  }