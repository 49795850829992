import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import {HubSection} from '../components/HubSection'
import { Layout } from '../components/Layout'
import { FellowsHubProvider } from '../utils/fellowsHubProvider'



const FellowsHubTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicFellowsHub || {}
  
  const page = pageContent.data || {}
  const menu = data.prismicMenu || {}
  const common = data.prismicCommon || {}
  const fellowsNodes = data.allAirtableFellows || {}
  let fellows = fellowsNodes.nodes || {}
  fellows = fellows.filter(f => f.data?.Profiles !== null);
  

  const { lang, type, url } = pageContent || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout menu={menu.data} common={common} activeDocMeta={activeDoc} theme={page.theme} title={page.title}>
      <FellowsHubProvider fellows={fellows}>
        <HubSection data={page} fellows={fellows} lang={lang}/>
      </FellowsHubProvider>
    </Layout>
  )
}

export const query = graphql`
  query fellowsHubQuery($id: String, $lang: String) {
    prismicFellowsHub(id: { eq: $id },lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      
      ...FellowsHubFragment
    }
    allAirtableFellows(
      filter: {
        data: {
          Profiles: {
            elemMatch: {
              data: {
                ID: { ne: null },
                Hide_Profile: { ne: true }
              }
            }
          }
        }
      }
    )  {
      nodes {
        id
        data {
          Fellow_Name
          Bio
          Bio_FR
          SDGs
          CardDescription
          Which_category_is_most_closely_associated_with_your_project_
          Communities_Served
          Community {
            data {
              Name
            }
          }
          Profiles {
            data {
              Hide_Profile
            }
          }
          Featured
          Project_Photo {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Headshot {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          Project_Name
          Project_Name_FR
          Project_Description
          Project_Description_FR
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    prismicCommon(lang: { eq: $lang }){
      ...CommonFragment
      ...SkipFragment
    }
  }
`

export default withPrismicPreview(FellowsHubTemplate);
