import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText,PrismicLink } from '@prismicio/react';
import Container from "../components/Container";
import TextBox from '../components/TextBox';
import { FAQ } from '../styles/slices/faq.module.scss'

export const Faq = ({ slice }) => {
 
  return (
    <section >
      <Container>
        <div style={{display:"flex",justifyContent:"center"}}>
          <TextBox tAlign={"center"}>
            <PrismicRichText field={slice.primary.header_richtext?.richText}/>
          </TextBox>
        </div>
        <div className={FAQ}>
          {slice.items.map((item,index) => (
            <details>
              <summary>{item.question}</summary>
              <PrismicRichText field={item.answer?.richText}/>
            </details>
          ))}     
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyFaq on PrismicPageDataBodyFaq {
    id
    primary {
      header_richtext {
        richText
      }
    }
    items {
      question
      answer {
        richText
      }
    }
  }
`
