export  const Categories = {
  en: {
    'Arts & Culture': 'Arts & Culture',
    'Education':'Education',
    'Environment':'Environment',
    'Food & Health':'Food & Health',
    'Housing & Safety':'Housing & Safety',
    'Social Justice':'Social Justice',
    'Sports & Recreation':'Sports & Recreation',
  },
  fr: {
    'Arts & Culture': 'Arts et culture',
    'Education':'Éducation',
    'Environment':'Environnement',
    'Food & Health':'Alimentation et santé',
    'Housing & Safety':'Logement et sécurité',
    'Social Justice':'Justice sociale',
    'Sports & Recreation':'Sports et loisirs',
  }
}

export const Communities = {
  en: [
    "At risk youth & children",
    "Indigenous Peoples",
    "LGBTQ2+ Community members",
    "Multi-barrier populations",
    "Persons with disabilities",
    "Racial or visible minorities",
    "Refugees & newcomers",
    "Senior citizens",
    "Vulnerable individuals (e.g. those experiencing addiction, abuse)",
  ],
  fr: [
    "Jeunes et enfants à risque",
    "Peuples autochtones",
    "Membres de la communauté LGBTQ2+",
    "Populations confrontées à des barrières multiples",
    "Personnes handicapées",
    "Minorités raciales ou visibles",
    "Réfugiés et nouveaux arrivants",
    "Personnes âgées",
    "Personnes vulnérables (p. ex. toxicomanes, victimes d’abus)",
  ]
}

export const SDGs = {
    en: [
      "SDG 1: No poverty",
      "SDG 2: Zero hunger",
      "SDG 3: Good health and Well-being",
      "SDG 4: Quality education",
      "SDG 5: Gender equality",
      "SDG 6: Clean water and sanitation",
      "SDG 7: Affordable and clean energy",
      "SDG 8: Decent work and economic growth",
      "SDG 9: Industry, innovation, and infrastructure",
      "SDG 10: Reduced inequalities",
      "SDG 11: Sustainable cities and communities",
      "SDG 12: Responsible consumption and production",
      "SDG 13: Climate action",
      "SDG 14: Life below water",
      "SDG 15: Life on land",
      "SDG 16: Peace, justice, and strong institutions",
      "SDG 17: Partnerships for the goals",
    ],
    fr: [
      "ODD 1 : Pas de pauvreté",
      "ODD 2 : Faim « zéro »",
      "ODD 3 : Bonne santé et bien-être",
      "ODD 4 : Éducation de qualité",
      "ODD 5 : Égalité entre les sexes",
      "ODD 6 : Eau propre et assainissement",
      "ODD 7 : Énergie propre et d’un coût abordable",
      "ODD 8: Travail décent et croissance économique",
      "ODD 9 : Industrie, innovation et infrastructure",
      "ODD 10 : Inégalités réduites",
      "ODD 11 : Villes et communautés durables",
      "ODD 12 : Consommation et production responsables",
      "ODD 13 : Mesures relatives à la lutte contre les changements climatiques",
      "ODD 14 : Vie aquatique",
      "ODD 15 : Vie terrestre",
      "ODD 16 : Paix, justice et institutions efficaces",
      "ODD 17 : Partenariats pour la réalisation des objectifs",
    ]
  }
