import React, { useState, useEffect, useContext, createContext } from 'react'

const fellowsContext = createContext()
export const useFellows = () => useContext(fellowsContext)

export function FellowsProvider({ fellows, children }) {
  const fel = useFellowsProvider(fellows)
  return <fellowsContext.Provider value={fel}>{children}</fellowsContext.Provider>
}

function useFellowsProvider(fel) {
  const [fellows, setFellows] = useState(fel)

  const [filter, setFilter] = useState([])
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState()

  useEffect(() => {
    updateFellows()
  }, [sort,filter,search])

  const handleSort = (val) => {
    setSort(val)
  }
  const handleSearch = (val) => {
    setSearch(val)

  }
  const handleFilter = (val) => {
    setFilter(val)

  }
  const updateFellows = () => {
    const filtered = applyFilters(fel, filter)
    const searched = applySearch(filtered, search)
    const sorted = applySort(searched, sort)
   
    setFellows(sorted)
  }
  function applySort(f, sort){
    const dummy = [...f]
    if(sort == 0){
      return dummy.sort((a,b) => {
        if(a.data.Fellow_Name.toLowerCase() < b.data.Fellow_Name.toLowerCase()) return -1;
        if(a.data.Fellow_Name.toLowerCase() > b.data.Fellow_Name.toLowerCase()) return 1;
        return 0;
      })
    } else if(sort == 1){
      return dummy.sort((a,b) => {
        if(a.data.Fellow_Name.toLowerCase() > b.data.Fellow_Name.toLowerCase()) return -1;
        if(a.data.Fellow_Name.toLowerCase() < b.data.Fellow_Name.toLowerCase()) return 1;
        return 0;
      })
    } else {
      return dummy
    }
  }
  function applySearch(f, search){
    const searchVal = search.toLowerCase()
    if(!search.length > 0) return f;
    const filtered = f.filter(
      fellow => {
        const proj = fellow.data.Project_Name || null
        return fellow.data.Fellow_Name.toLowerCase().includes(searchVal) || (proj != null ? proj[0].toLowerCase().includes(searchVal) : false)
      }
    )
    
    return filtered || []
  }
  function applyFilters(f, filter) {
    if (!filter.length) {
      return f;
    }
    
    return f.filter(fellow => {
      if (!fellow.data) return false;
      
      const community = fellow.data.Community ? fellow.data.Community[0]?.data : null;
      
      return filter.some(val => 
        (fellow.data.Which_category_is_most_closely_associated_with_your_project_?.includes(val) || false) ||
        (fellow.data.Communities_Served?.includes(val) || false) ||
        (community?.Name?.includes(val) || false) ||
        (fellow.data.SDGs?.includes(val) || false)
      );
    });
  }
  return {
    fellows,
    handleSort,
    handleSearch,
    handleFilter,
  }
}
