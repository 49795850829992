import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import "../styles/base/module.scss";

import { TopMenu } from './TopMenu';
import { BottomMenu } from './BottomMenu';


export const Layout = ({ children, menu, common, theme = true, activeDocMeta, title }) => {

  React.useEffect(() => {
    if(!theme){ 
      document.body.style.backgroundColor = "#AFDEE2";
    } else 
    {
      document.body.style.backgroundColor = "#96C055";
    }

    return () => {
      // 👇️ optionally remove styles when component unmounts
      document.body.style.backgroundColor = null;

    };
  }, []);

  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  const titleAddition = activeDocMeta.lang == 'fr-ca' ? 'Idées de germes' : 'Sprout Ideas'
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: activeDocMeta.lang
        }}
      >
        <meta charSet="utf-8" />
        <title>{title ? `${title} | ${titleAddition}` : titleAddition}</title>
        <meta
          name="description"
          content={queryData.site.siteMetadata.description}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          href="https://fonts.googleapis.com/css?family=Lato:300,400,700,900"
          rel="stylesheet"
          type="text/css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
		<script>
          {`
            (function(w,d,s,n,a){if(!w[n]){var l='call,catch,on,once,set,then,track'
              .split(','),i,o=function(n){return'function'==typeof n?o.l.push([arguments])&&o
              :function(){return o.l.push([n,arguments])&&o}},t=d.getElementsByTagName(s)[0],
              j=d.createElement(s);j.async=!0;j.src='https://cdn.fundraiseup.com/widget/'+a;
              t.parentNode.insertBefore(j,t);o.s=Date.now();o.v=4;o.h=w.location.href;o.l=[];
              for(i=0;i<7;i++)o[l[i]]=o(l[i]);w[n]=o}
              })(window,document,'script','FundraiseUp','ANFTSWCF');
        `}
		</script>
      </Helmet>
      <TopMenu menu={menu} common={common} activeDocMeta={activeDocMeta} theme={theme}/>
      <main id="main">{children}</main>
      <BottomMenu menu={menu} common={common}/>
    </>
  )
}
