import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import FellowInfoSection from '../components/FellowInfoSection'
import ProjectInfoSection from '../components/ProjectInfoSection'
import AboutProjectSection from '../components/AboutProjectSection'
import IMTSection from '../components/IMTSection'
import VideoSection from '../components/VideoSection'
import DonateSection from '../components/DonateSection'
import Carousel from '../components/Carousel'
import slugify from '../utils/slugify'

const FellowTemplate = ({ data }) => {
  if(!data) return null

  
  const fellowCommonContent = data.prismicFellowsCommon
  const lang = fellowCommonContent.lang

  const fellowContent = data.airtableFellows
  const fellow = fellowContent.data
  const fCommon = fellowCommonContent.data
  const menu = data.prismicMenu || {}
  const common = data.prismicCommon || {}

  
  const alternateLanguages = []
  fellowCommonContent.alternate_languages.map(alt => alternateLanguages.push({
    type : 'fellow',
    lang : alt.lang,
    url : `/${lang.slice(0,2)}/fellows/${fellowContent.id}`,
    uid : slugify(fellow.Fellow_Name)
  }))
  const type = 'fellow'
  const url = `/${lang.slice(0,2)}/fellows/${fellowContent.id}`
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const options = lang.slice(0,2).toLowerCase() === 'fr' ? {
    bio: fellow.Bio_FR || fellow.Bio,
    Project_Name: fellow.Project_Name_FR || fellow.Project_Name,
    Project_Description: fellow.Project_Description_FR || fellow.Project_Description,
    How_the_project_works: fellow.How_the_project_works_FR || fellow.How_the_project_works,
    Impact_Summary: fellow.Impact_Summary_FR || fellow.Impact_Summary,
    Mission_Summary: fellow.Mission_Summary_FR || fellow.Mission_Summary,
    Project_Team_Overview: fellow.Project_Team_Overview_FR || fellow.Project_Team_Overview,
  } :
  {
    bio: fellow.Bio || fellow.Bio_FR,
    Project_Name: fellow.Project_Name || fellow.Project_Name_FR,
    Project_Description: fellow.Project_Description || fellow.Project_Description_FR,
    How_the_project_works: fellow.How_the_project_works || fellow.How_the_project_works_FR,
    Impact_Summary: fellow.Impact_Summary || fellow.Impact_Summary_FR,
    Mission_Summary: fellow.Mission_Summary || fellow.Mission_Summary_FR,
    Project_Team_Overview: fellow.Project_Team_Overview || fellow.Project_Team_Overview_FR,
  }
  

  return (
    <Layout menu={menu.data} common={common} activeDocMeta={activeDoc} title={fellow.Fellow_Name}>
      <FellowInfoSection
        title={fellow.Fellow_Name}
        description={options.bio}
        location={fellow.Community[0].data.Name}
        page_link={url}
        image={fellow.Headshot?.localFiles[0]}
        socials={{
          tiktok: fellow.Personal_TikTok,
          facebook: fellow.Personal_Facebook,
          instagram: fellow.Personal_Instagram,
          linkedin: fellow.Personal_LinkedIn,
        }}
        share_page_label={fCommon.share_page_label}
      />
      
      <ProjectInfoSection
        name={options.Project_Name}
        categories={fellow.Which_category_is_most_closely_associated_with_your_project_}
        description={options.Project_Description}
        image={fellow.Project_Photo?.localFiles[0]}
        socials={{
          website: fellow.Project_Website,
          facebook: fellow.Project_Facebook,
          instagram: fellow.Project_Instagram,
          linkedin: fellow.Project_LinkedIn,
        }}
      />
      
      <AboutProjectSection
        title={fCommon.about_project_header}
        communities={fellow.Communities_Served}
        communitiesInfo={{
          header: fCommon.communities_header,
          icon: fCommon.communities_icon,
          description: fCommon.communities_description,
        }}
        goals={fellow.SDGs}
        goalsInfo={{
          header: fCommon.goals_header,
          icon: fCommon.goals_icon,
          description: fCommon.goals_description,
        }}
        lang={lang.slice(0,2).toLowerCase()}
      />
      
      <IMTSection
        title={fCommon.project_works_header}
        description={options.How_the_project_works}
        impact={{
          header: fCommon.impact_header,
          description: options.Impact_Summary,
          image: fellow.Impact_Photo?.localFiles[0],
        }}
        mission={{
          header: fCommon.mission_header,
          description: options.Mission_Summary,
          image: fellow.Mission_Photo?.localFiles[0],
        }}
        team={{
          header: fCommon.team_header,
          description: options.Project_Team_Overview,
          members: fellow.Project_Team_Members,
          image: fellow.Project_Team_Photo_Photo?.localFiles[0],
        }}

      />
      { fellow.Video_Link  && 
      <VideoSection
        title={`Video Name or info`}
        description={fellow.Video_Description}
        video={fellow.Video_Link}
      />
      }
      {
        fellow.Additional_Photos &&
        <Carousel
          title={fCommon.photo_gallery_header}
          images={fellow.Additional_Photos?.localFiles}
          slide_width={320}
        />
      }
      <div className="donate">
        <DonateSection
          text={fCommon.donate_text}
          btn_label={fCommon.donate_btn_label}
          btn_link={fCommon.donate_btn_link}
        />
      </div> 
    </Layout>
  )
}
export const query = graphql`
  query fellowQuery($id: String, $lang: String) {
    airtableFellows(id: {eq: $id}) {
      id
      data {
        Fellow_Name
        Bio
        Community {
          data {
            Name
          }
        }
        Headshot {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 540)
            }
          }
        }
        Personal_Facebook
        Personal_Instagram
        Personal_LinkedIn

        Project_Name
        Project_Description
        Project_Facebook
        Project_Instagram
        Project_LinkedIn
        Project_Website
        Project_Photo {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 540)
            }
          }
        }

        Communities_Served
        SDGs

        How_the_project_works
        Impact_Summary
        Impact_Photo {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 673)
            }
          }
        }
        Mission_Summary
        Mission_Photo {
          localFiles {
            childImageSharp {
              gatsbyImageData(width: 673)
            }
          }
        }
        Project_Team_Photo {

          localFiles {
            childImageSharp {
              gatsbyImageData(width: 673)
            }
          }
        }
        Project_Team_Overview
        Project_Team_Members
        Video_Description
        Video_Link
        Additional_Photos {
          localFiles {
            childImageSharp {
              gatsbyImageData
            }
          }
        }

        Bio_FR
        Project_Name_FR
        Project_Description_FR
        How_the_project_works_FR
        Impact_Summary_FR
        Mission_Summary_FR
        Project_Team_Overview_FR

      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    prismicCommon(lang: { eq: $lang }){
      ...CommonFragment
      ...SkipFragment
    }
    prismicFellowsCommon(lang: { eq: $lang }){
      id
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        about_project_header
        communities_description
        communities_header
        communities_icon {
          alt
          gatsbyImageData
          dimensions {
            height
            width
          }
        }
        donate_btn_label
        donate_btn_link {
          url
        }
        donate_text {
          richText
        }
        goals_description
        goals_header
        goals_icon {
          gatsbyImageData
          alt
          dimensions {
            height
            width
          }
        }
        impact_header
        mission_header
        photo_gallery_header
        project_works_header
        team_header
        share_page_label
      }
    }
  }
`
export default FellowTemplate;