import React from 'react';

const TextBox = ({ children, tAlign, width=Number, style  }) => {

  return (
    <div className="textBox" style={{textAlign:tAlign, maxWidth:width, ...style}}>
      {children}
    </div>
  )};

export default TextBox;