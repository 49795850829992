// extracted by mini-css-extract-plugin
export var BtnPrimary = "faq-module--BtnPrimary--38c6c";
export var BtnPrimaryAlt = "faq-module--BtnPrimaryAlt--c690f";
export var Container = "faq-module--Container--985bd";
export var FAQ = "faq-module--FAQ--81f92";
export var FlexWrap = "faq-module--FlexWrap--01340";
export var H1 = "faq-module--H1--8e0a2";
export var H2 = "faq-module--H2--a8a57";
export var H3 = "faq-module--H3--e29a1";
export var H4 = "faq-module--H4--8b1ab";
export var H5 = "faq-module--H5--b5c2b";
export var H6 = "faq-module--H6--52916";
export var airTableImg = "faq-module--airTableImg--bed2c";
export var circleText = "faq-module--circleText--3f76d";
export var cssMohuvpDummyInputDummyInput = "faq-module--css-mohuvp-dummyInput-DummyInput--e3c5a";
export var donate = "faq-module--donate--856e8";
export var imageBox = "faq-module--imageBox--13466";
export var next = "faq-module--next--2547c";
export var pagination = "faq-module--pagination--67524";
export var pagination__link = "faq-module--pagination__link--7234a";
export var pagination__linkActive = "faq-module--pagination__link--active--d3c6f";
export var pagination__linkDisabled = "faq-module--pagination__link--disabled--a2555";
export var previous = "faq-module--previous--cce7d";
export var reactSelect__control = "faq-module--react-select__control--15f40";
export var reactSelect__indicator = "faq-module--react-select__indicator--d2c48";
export var reactSelect__multiValue = "faq-module--react-select__multi-value--d9cdd";
export var reactSelect__multiValue__remove = "faq-module--react-select__multi-value__remove--5868b";
export var reactSelect__option = "faq-module--react-select__option--19cb1";
export var reactSortContainer = "faq-module--react-sort-container--55cb6";
export var reactSort__control = "faq-module--react-sort__control--5e3a5";
export var reactSort__indicator = "faq-module--react-sort__indicator--7c5c4";
export var reactSort__indicatorSeparator = "faq-module--react-sort__indicator-separator--e7b4e";
export var reactSort__placeholder = "faq-module--react-sort__placeholder--5508f";
export var tagline = "faq-module--tagline--d58ca";
export var textBox = "faq-module--textBox--1d2b7";
export var videoContainer = "faq-module--videoContainer--e21d4";
export var white = "faq-module--white--d548b";