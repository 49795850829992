import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText, PrismicLink } from '@prismicio/react';
import Container from "../components/Container";
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox';
import { FlexWrap, FlexWrapAlt, ContentContainer } from '../styles/slices/text_image.module.scss'
import { customComponents } from '../utils/customComponents'

export const ContentArea = ({ slice }) => {

  return (
    <section className={ContentContainer}>
      <Container>
        <TextBox width={860}>
          <PrismicRichText
            field={slice.primary.text?.richText}
            components={customComponents}
          />
        </TextBox>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyContentArea on PrismicPageDataBodyContentArea {
    id
    primary {
      text {
        richText
      }
    }
  }
`
