import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import {DirectorySection} from '../components/DirectorySection'
import { Layout } from '../components/Layout'
import { FellowsProvider } from '../utils/fellowsProvider'

const FellowsDirectoryTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicFellowsDirectory || {}
  const page = pageContent?.data || {}
  const fellowsNodes = data.allAirtableFellows || {}
  let fellows = fellowsNodes.nodes || {}
  fellows = fellows.filter(f => f.data?.Profiles !== null);
  
  const featured = []
  page.featured_fellows.map(fellow => {
    featured.push(fellows.find(f => f.data.Fellow_Name.toLowerCase() == fellow.fellow_name.toLowerCase()))
  })
  const menu = data.prismicMenu || {}
  const common = data.prismicCommon || {}
  
  const { lang, type, url } = pageContent || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout menu={menu.data} activeDocMeta={activeDoc} theme={true} common={common} title={page.page_title?.text}>
      <FellowsProvider fellows={fellows}>
        <DirectorySection
          data={page}
          lang={lang.slice(0,2).toLowerCase()}
        />
      </FellowsProvider>
    </Layout>
  )
}

export const query = graphql`
  query fellowsDirectoryQuery($lang: String) {
    prismicFellowsDirectory(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      
      ...FellowsFragment
    }
    allAirtableFellows(
      filter: {
        data: {
          Profiles: {
            elemMatch: {
              data: {
                ID: { ne: null },
                Hide_Profile: { ne: true }
              }
            }
          }
        }
      }
    ) {
      nodes {
        id
        data {
          Fellow_Name
          Bio
          Bio_FR
          SDGs
          CardDescription
          Which_category_is_most_closely_associated_with_your_project_
          Communities_Served
          Community {
            data {
              Name
            }
          }
          Project_Photo {
            localFiles {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Headshot {
            localFiles {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          Profiles {
            data {
              ID
              Hide_Profile
            }
          }
          Project_Name
          Project_Name_FR
          Project_Description
          Project_Description_FR
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    prismicCommon(lang: { eq: $lang }){
      ...CommonFragment
      ...SkipFragment
    }
  }
`

export default withPrismicPreview(FellowsDirectoryTemplate)
