import * as React from 'react'
import Container from '../components/Container'
import { FaFacebook, FaInstagram, FaLinkedin,FaShareSquare } from 'react-icons/fa'
import TextBox from './TextBox'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as s from '../styles/components/FellowInforSection.module.scss'

const FellowInfoSection = (props) => {
  const {
    title,
    description,
    location,
    page_link,
    image,
    socials,
    share_page_label
  } = props
  const [copied, setCopied] = React.useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(window?.location?.href)
    setCopied(true)
  }
  return (
    <section className={s.FellowInfoSection}>
      <Container>
        <div className={s.FlexWrap}>
          {/* <ImageBox srcUrl={image.url} width={540} aspect={image.width/image.height}/> */}
          {image && <GatsbyImage image={image?.childImageSharp?.gatsbyImageData} alt="" /> }
          <TextBox width={745}>
            <div>
              <h1>{title} </h1>
              <span className={s.location}>{location}</span>
              
              {/* make prismic */}
              
              <button onClick={handleCopy} style={{cursor: 'pointer'}} className={s.BtnPrimary}>{share_page_label}  <FaShareSquare/> {copied && <span style={{position: 'absolute',color: 'black',top: '120%'}}>Copied!</span>}</button>
              
            </div>
            <p>{description}</p>
            <div className={s.social}>
              {socials.linkedin &&
              <a href={`https://www.linkedIn.com/in/${socials.linkedin}`} aria-label="Linkedin Profile" target="_blank">
                <FaLinkedin/>
              </a>
              }
            </div>
          </TextBox>
        </div>
      </Container> 
    </section>
  )
}
export default FellowInfoSection;
