import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { FaChevronRight, FaChevronLeft, FaDownload } from 'react-icons/fa'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useWindowWidth } from '@react-hook/window-size'
import Container from "../components/Container"
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox'

import { Card, CarouselWrap, btnNav, btnBack, btnNext, CardBtn } from '../styles/slices/carousel.module.scss'

const container_width = 1440
const slide_width = 430
const slide_height = 540

function calcWidth(screen, totalSlides){
  var maximumWidth = totalSlides
  var minimumWidth = 1

  var margin = Math.max(((screen - container_width) / 2), 0);
  var adjustedWidth = screen - margin;
  adjustedWidth = Math.floor(adjustedWidth/slide_width)
  if(adjustedWidth > maximumWidth){
    return maximumWidth
  } else if(adjustedWidth < minimumWidth){
    return minimumWidth
  } else {
    return adjustedWidth
  }
}
export const Carousel = ({ slice }) => {
  const total_slides = slice.items.length;
  const width = useWindowWidth();
  const [slider_width,setSliderWidth] = React.useState(0)
  
  React.useEffect(() => (
    setSliderWidth(calcWidth(width,total_slides))
  ),[width])
  return (
    <section style={{paddingBottom: 40}}>
      <Container>
        <TextBox>
          <PrismicRichText field={slice.primary.header_text?.richText}/>
        </TextBox>
        <CarouselProvider
            naturalSlideWidth={slide_width + 20}
            
            totalSlides={total_slides}
            visibleSlides={slider_width}
            infinite={true}
            step={1}
            isIntrinsicHeight={true}
            className={CarouselWrap}
          >

            { total_slides > 3 || slider_width <= 2 &&
              <div className={btnNav}>
                <ButtonBack className={btnBack} ><FaChevronLeft aria-label="left arrow" /></ButtonBack>
                <ButtonNext className={btnNext} ><FaChevronRight aria-label="right arrow" /></ButtonNext>
              </div>  
            }
            <Slider classNameTray="slider-tray" classNameTrayWrap="slider-tray-wrap">
              {slice.items.map((item,index) => (
                <Slide index={index} key={`carousel: ${index}`} tabIndex={-1} classNameHidden="hidden-slide">
                  <div className={Card}>
                    <ImageBox
                      image={item.image}
                    />
                    <div style={{padding: "10px 25px 25px"}}>
                      <h3>{item.card_header}</h3>
                      <p>{item.card_copy}</p>
                      <PrismicLink 
                        href={item.button_link?.url}
                        className={CardBtn}
                      >
                        {item.button_label} <FaDownload/>
                      </PrismicLink>
                    </div> 
                  </div>
                </Slide>
              ))}
            </Slider>   
          </CarouselProvider>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyCarousel on PrismicPageDataBodyCarousel {
    id
    primary {
      header_text {
        richText
      }
    }
    items {
      button_label
      button_link {
        url
      }
      card_copy
      card_header
      image {
        gatsbyImageData
        alt
        dimensions {
          width
          height
        }
      }
    }
  }
`
