import React from 'react'
import Container from './Container'
import { 
  CarouselProvider,
  Slide, 
  Slider,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { PrismicLink } from '@prismicio/react'

import {  useFellowsHub } from '../utils/fellowsHubProvider'
import { GatsbyImage } from 'gatsby-plugin-image';
import * as s from '../styles/components/SDGFilter.module.scss'
import useIsMobile from '../utils/useIsMobile'

const SDGFilter= ({listOfSDGs, title, buttonLabel, searchAllFellowsLink}) => {
  const { setSelectedSDGId, selectedSDGId} = useFellowsHub()
  const isMobile = useIsMobile()

  const totalSlides = listOfSDGs.length

  const selectedSDG = listOfSDGs.find(({sdg_id})=>sdg_id===selectedSDGId)
 
  return (
    <section className={s.section}>
      <Container>
        <h3 className={s.sectionTitle}>{title}</h3>
      <CarouselProvider
          naturalSlideWidth={100}
          totalSlides={totalSlides}
          visibleSlides={isMobile ? 2 : 8}
          infinite={false}
          step={isMobile ? 2: 7}
          isIntrinsicHeight={true}
        >
            <div className={s.carouselControlsContainer}>
            <PrismicLink className={s.prismicLink} href={searchAllFellowsLink}>{buttonLabel}</PrismicLink>
            <div className={s.carouselControls}>
              <ButtonBack ><FaChevronCircleLeft  className={s.fa} aria-label="left arrow" /></ButtonBack>
              <ButtonNext><FaChevronCircleRight className={s.fa} aria-label="right arrow" /></ButtonNext>
            </div>
          </div> 
          <Slider 
            classNameTray="slider-tray"
            classNameTrayWrap="slider-tray-wrap"
          >
            {listOfSDGs?.map(({sdg_icon, sdg_id},index) => (
              <Slide 
                index={index} 
                key={`carousel: ${index}`} 
                tabIndex={-1} 
                classNameHidden="hidden-slide"
              >
                <button onClick={()=>setSelectedSDGId(sdg_id)} className={s.sdgIconButton}>
                  <GatsbyImage
                    className={selectedSDGId===sdg_id ? s.SDGIconActive : s.SDGIcon}
                    image={sdg_icon?.gatsbyImageData}
                    alt={sdg_icon?.alt || ""}
                    style={{borderRadius:'22px'}}
                  />
                </button>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
        <div className={s.sdgTitleSection}>
          <h3>{selectedSDG.sdg_title}</h3>
          <p>{selectedSDG.sdg_description}</p>
        </div>
      </Container>
    </section>
  )
}
export default SDGFilter
