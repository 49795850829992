// extracted by mini-css-extract-plugin
export var BtnPrimary = "text_image-module--BtnPrimary--85d51";
export var BtnPrimaryAlt = "text_image-module--BtnPrimaryAlt--37224";
export var Container = "text_image-module--Container--e66e2";
export var ContentContainer = "text_image-module--ContentContainer--6a164";
export var Dot1 = "text_image-module--Dot1--43940";
export var Dot2 = "text_image-module--Dot2--16926";
export var Dot3 = "text_image-module--Dot3--bd5ec";
export var FlexGrid = "text_image-module--FlexGrid--ef556";
export var FlexWrap = "text_image-module--FlexWrap--f5428";
export var FlexWrapAlt = "text_image-module--FlexWrapAlt--f7b10";
export var H1 = "text_image-module--H1--68be6";
export var H2 = "text_image-module--H2--5ac19";
export var H3 = "text_image-module--H3--ef1a4";
export var H4 = "text_image-module--H4--5bd4a";
export var H5 = "text_image-module--H5--387c0";
export var H6 = "text_image-module--H6--afff3";
export var ImageStatContainer = "text_image-module--ImageStatContainer--47984";
export var airTableImg = "text_image-module--airTableImg--c5e64";
export var circleText = "text_image-module--circleText--534aa";
export var cssMohuvpDummyInputDummyInput = "text_image-module--css-mohuvp-dummyInput-DummyInput--23a18";
export var donate = "text_image-module--donate--77cf8";
export var dot = "text_image-module--dot--27a20";
export var imageBox = "text_image-module--imageBox--4faeb";
export var next = "text_image-module--next--5ba99";
export var pagination = "text_image-module--pagination--7d74b";
export var pagination__link = "text_image-module--pagination__link--a18db";
export var pagination__linkActive = "text_image-module--pagination__link--active--a1c2f";
export var pagination__linkDisabled = "text_image-module--pagination__link--disabled--e3068";
export var previous = "text_image-module--previous--8c11f";
export var reactSelect__control = "text_image-module--react-select__control--6fc2e";
export var reactSelect__indicator = "text_image-module--react-select__indicator--55de4";
export var reactSelect__multiValue = "text_image-module--react-select__multi-value--a94cb";
export var reactSelect__multiValue__remove = "text_image-module--react-select__multi-value__remove--ce291";
export var reactSelect__option = "text_image-module--react-select__option--fbeb7";
export var reactSortContainer = "text_image-module--react-sort-container--33ebc";
export var reactSort__control = "text_image-module--react-sort__control--8f1d0";
export var reactSort__indicator = "text_image-module--react-sort__indicator--8787f";
export var reactSort__indicatorSeparator = "text_image-module--react-sort__indicator-separator--33d92";
export var reactSort__placeholder = "text_image-module--react-sort__placeholder--6497e";
export var tagline = "text_image-module--tagline--c2d5e";
export var textBox = "text_image-module--textBox--70c53";
export var videoContainer = "text_image-module--videoContainer--6c433";
export var white = "text_image-module--white--e334d";