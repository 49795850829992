// extracted by mini-css-extract-plugin
export var BtnPrimary = "FeaturedSDG-module--BtnPrimary--f120e";
export var BtnPrimaryAlt = "FeaturedSDG-module--BtnPrimaryAlt--320f0";
export var Container = "FeaturedSDG-module--Container--409bb";
export var FlexWrap = "FeaturedSDG-module--FlexWrap--ff4a6";
export var H1 = "FeaturedSDG-module--H1--44126";
export var H2 = "FeaturedSDG-module--H2--e3c5e";
export var H3 = "FeaturedSDG-module--H3--03dd7";
export var H4 = "FeaturedSDG-module--H4--a5046";
export var H5 = "FeaturedSDG-module--H5--72598";
export var H6 = "FeaturedSDG-module--H6--4ac58";
export var airTableImg = "FeaturedSDG-module--airTableImg--dd393";
export var carouselContainer = "FeaturedSDG-module--carouselContainer--069a5";
export var carouselControls = "FeaturedSDG-module--carouselControls--ad617";
export var circleText = "FeaturedSDG-module--circleText--a6a5b";
export var cssMohuvpDummyInputDummyInput = "FeaturedSDG-module--css-mohuvp-dummyInput-DummyInput--88c22";
export var donate = "FeaturedSDG-module--donate--278a6";
export var fa = "FeaturedSDG-module--fa--eaa12";
export var featuredCardContainer = "FeaturedSDG-module--featuredCardContainer--66130";
export var imageBox = "FeaturedSDG-module--imageBox--ed7a1";
export var imageContainer = "FeaturedSDG-module--imageContainer--3a23a";
export var next = "FeaturedSDG-module--next--01693";
export var pagination = "FeaturedSDG-module--pagination--e5b93";
export var pagination__link = "FeaturedSDG-module--pagination__link--63b6c";
export var pagination__linkActive = "FeaturedSDG-module--pagination__link--active--12112";
export var pagination__linkDisabled = "FeaturedSDG-module--pagination__link--disabled--8aa1e";
export var previous = "FeaturedSDG-module--previous--0fdf0";
export var projectContainer = "FeaturedSDG-module--projectContainer--436b1";
export var reactSelect__control = "FeaturedSDG-module--react-select__control--dfca0";
export var reactSelect__indicator = "FeaturedSDG-module--react-select__indicator--48dfd";
export var reactSelect__multiValue = "FeaturedSDG-module--react-select__multi-value--9f135";
export var reactSelect__multiValue__remove = "FeaturedSDG-module--react-select__multi-value__remove--11c6f";
export var reactSelect__option = "FeaturedSDG-module--react-select__option--28f56";
export var reactSortContainer = "FeaturedSDG-module--react-sort-container--7b0a5";
export var reactSort__control = "FeaturedSDG-module--react-sort__control--85007";
export var reactSort__indicator = "FeaturedSDG-module--react-sort__indicator--939d9";
export var reactSort__indicatorSeparator = "FeaturedSDG-module--react-sort__indicator-separator--36036";
export var reactSort__placeholder = "FeaturedSDG-module--react-sort__placeholder--59fdf";
export var section = "FeaturedSDG-module--section--68f8d";
export var tagline = "FeaturedSDG-module--tagline--ee12a";
export var textBox = "FeaturedSDG-module--textBox--d0529";
export var titleSection = "FeaturedSDG-module--titleSection--a0061";
export var videoContainer = "FeaturedSDG-module--videoContainer--df0d5";
export var white = "FeaturedSDG-module--white--39e3f";