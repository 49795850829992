import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText,PrismicLink } from '@prismicio/react';
import Container from "../components/Container";
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox';
import { FlexWrap, FlexWrapAlt, Dot1, Dot2, parallaxLayerBase, parallax, parallaxLayerBack } from '../styles/slices/text_image.module.scss'

export const TextImage = ({ slice }) => {
  const justify = slice.primary.background_image?.url ? FlexWrapAlt : FlexWrap
  const Text = ({}) => (
    <TextBox width={610}>
      <PrismicRichText field={slice.primary.text?.richText}/>
      <PrismicLink
        href={slice.primary.button_link?.url}
        className={"BtnPrimary"}
      >
        {slice.primary.button_label}
      </PrismicLink>
    </TextBox>
  );
  const Image = ({}) =>{ 
    return(
      <ImageBox
        image={slice.primary.image}
        borderRadius={20}
      />
    )
  };

  const bgStyle = slice.primary.background_image?.url && {
    backgroundImage: "url(" + slice.primary.background_image?.url + ")",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  return (
    <section style={bgStyle}>
      <Container>
        <div className={justify}>
          {
            slice.primary.imageSide ? (  
              <>
                <Image/>
                <Text/>
              </>  
            ) : (
              <>
                <Text/>
                <Image/>
              </> 
            )
          }

        </div>
        
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTextImage on PrismicHomepageDataBodyTextImage {
    id
    primary {
      background_color
      background_image {
        url
      }
      button_label
      button_link {
        url
      }
      image {
        fluid {
          srcWebp
          ...GatsbyPrismicImageFluid
        }
        gatsbyImageData(
          placeholder: BLURRED
        )
        alt
        dimensions {
          width
          height
        }
      }
      image_side
      text {
        richText
      }
    }
  }
  fragment PageDataBodyTextImage on PrismicPageDataBodyTextImage {
    id
    primary {
      background_color
      background_image {
        url
      }
      button_label
      button_link {
        url
      }
      image {
        gatsbyImageData(
          placeholder: BLURRED
        )
        fluid {
          srcWebp
          ...GatsbyPrismicImageFluid
        }
        alt
        dimensions {
          width
          height
        }
      }
      image_side
      text {
        richText
      }
    }
  }
`
