import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Container from "../components/Container"
import TextBox from '../components/TextBox'
import { Row, Card } from '../styles/slices/card_row.module.scss'

export const CardRow = ({ slice }) => {
  return (
    <section>
      <Container>
        <div style={{display:'flex',justifyContent:'center'}}>
          <TextBox
            width={695}
            tAlign={"center"}
          >
            <PrismicRichText field={slice.primary.text?.richText}/>
          </TextBox>
        </div>
        <div className={Row}>
          {slice.items.map((item,index) => (
            <div className={Card}>
              <PrismicRichText field={item.card_text?.richText}/>
            </div>
          ))}
        </div>
        <div style={{display:'flex',justifyContent:'center'}}>
          <PrismicLink href={slice.primary.button_link?.url} className="BtnPrimary">
            <span>{slice.primary.button_label}</span>
          </PrismicLink>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment HomepageDataBodyCardRow on PrismicHomepageDataBodyCardRow {
    id
    primary {
      button_label
      button_link {
        url
      }
      text {
        richText
      }
      text_align
    }
    items {
      card_text {
        richText
      }
    }
  }
  fragment PageDataBodyCardRow on PrismicPageDataBodyCardRow {
    id
    primary {
      button_label
      button_link {
        url
      }
      text {
        richText
      }
      text_align
    }
    items {
      card_text {
        richText
      }
    }
  }
`
