import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { Container } from './Container'
import { LanguageSwitcher } from './LanguageSwitcher'
import * as s from '../styles/components/TopMenu.module.scss'

export const TopMenu = ({ menu, common, activeDocMeta, theme=true }) => {
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const themeColor = theme ? "#96C055" : "#AFDEE2" ;

  return (
    <>
    <a className={s.skip_button} href="#main">   
      Skip to Content
    </a>
    <header className={s.header}>
      <LanguageSwitcher activeDocMeta={activeDocMeta} theme={theme} label={menu?.language_label}/>
      <div className={s.menu}>
        <div>
          <PrismicLink 
            href={menu?.home_link?.url}
            className={s.logoWrap}
            style={{aspectRatio:`${menu?.logo?.dimensions.width} / ${menu?.logo?.dimensions.height}`}}
          >
            <GatsbyImage
              image={menu?.logo?.gatsbyImageData}
              alt={menu?.logo?.alt || "Sprout"}
              className={s.logo}
            />
          </PrismicLink>
        </div>
        <div className={s.navMenu} style={{ background: themeColor}}>
          <div className={s.triangle} role="presentation" style={{ boxShadow: '38px -1px 0 0' + themeColor }}></div>
          <PrismicLink href={menu?.apply_button_link?.url} className="BtnPrimary DonateA" style={{marginRight: 35, position: 'relative', zIndex:5}}>
            {menu?.apply_button_label}
          </PrismicLink>
          <span className={s.menuLabel} id="menuText">{menu?.menu_label}</span>
          <button className={click ? s.navIconClose : s.navIcon} aria-labelledby="menuText" onClick={handleClick} tabIndex="0">
            <span className={s.bar1}/>
            <span className={s.bar2}/>
            <span className={s.bar3}/>
          </button>
          <div className={s.subMenu} style={{ background: themeColor}}>
            {menu?.local_links.map((item,index) => (
              <PrismicLink href={item.page_link?.url} className={s.localLink} key={index}>
                {item.page_label}
              </PrismicLink>
            ))}
            <PrismicLink href={menu?.home_link?.url} className={s.localLink}>
              {menu?.home_label}
            </PrismicLink>
            <PrismicLink href={menu?.apply_button_link?.url} className="BtnPrimary" style={{marginRight: 35, position: 'relative', zIndex:5}}>
              {menu?.apply_button_label}
            </PrismicLink>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      apply_button_label
      apply_button_link {
        url
      }
      home_label
      home_link {
        url
      }
      language_label
      local_links {
        page_link {
          url
        }
        page_label
      }
      menu_label
      logo {
        gatsbyImageData
        alt
        dimensions {
          width
          height
        }
      }
    }
  }
  fragment SkipFragment on PrismicCommon {
    data {
      skip_to_content
    }
  }
`
