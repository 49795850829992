import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import ImageBox from './ImageBox'
import * as sty from "../styles/components/BottomMenu.module.scss"

export const BottomMenu = ({menu,common}) => {
  const socials = common?.data?.socials;
  return (
    <footer style={{maxWidth: 1600, padding: '80px 20px'}}>
      <div className={sty.FlexWrap}>
        <div className={sty.column}>
          <span className={sty.colHeader}>{menu?.footer_local_label}</span>
          {menu?.footer_local_links.map((item,index) => (
            <PrismicLink href={item.page_link?.url} key={index}>
              {item.page_label}
            </PrismicLink>
          ))}
          <PrismicLink href={menu?.home_link?.url}>
            {menu?.home_label}
          </PrismicLink>
        </div>
        <div className={sty.column}>
          <span className={sty.colHeader}>{menu?.fellowship_label}</span>
          {menu?.fellowship_links.map((item,index) => (
            <PrismicLink href={item.link?.url} key={index}>
              {item.link_label}
            </PrismicLink>
          ))}
        </div>
        <div className={sty.column}>
          <span className={sty.colHeader}>{menu?.socials_label}</span>
          {socials?.map((item,index) => (
            <div style={{display:'flex', alignItems: 'center'}} key={index}>
              <div style={{width: 44}}>
                <GatsbyImage
                  image={item.icon?.gatsbyImageData}
                  alt={item.icon?.alt || ""}
                />
              </div>
              <PrismicLink href={item.link?.url}>
                {item.handle}
              </PrismicLink>
              
            </div>
            
          ))}
        </div>
        <div className={sty.column}>
          <span className={sty.colHeader}>{menu?.produced_label}</span>
          {menu?.produced_links.map((item,index) => (
            <PrismicLink href={item.link?.url} key={index}>
              <ImageBox
                image={item.image}
                width={283}
              />
            </PrismicLink>
          ))}
        </div>
      </div>
      <div className={sty.Copyright}>
        <PrismicLink href={menu?.copyright_link?.url}>
          <GatsbyImage
            image={menu?.copyright_image?.gatsbyImageData}
            alt={menu?.copyright_image?.alt || "Copyright"}
          />
        </PrismicLink>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment BottomMenuFragment on PrismicMenu {
    _previewable
    type
    lang
    data {
      copyright_image {
        gatsbyImageData
        alt
      }
      copyright_label
      copyright_link {
        url
      }
      fellowship_label
      fellowship_links {
        link {
          url
        }
        link_label
      }
      home_label
      home_link {
        url
      }
      footer_local_label
      footer_local_links {
        page_link {
          url
        }
        page_label
      }
      produced_label
      produced_links {
        link {
          url
        }
        image {
          gatsbyImageData
          alt
          dimensions {
            width
            height
          }
        }
      }
      socials_label
    }
  }
  fragment CommonFragment on PrismicCommon {
    data {
      seo_title
      circle_text
      socials {
        handle
        icon {
          gatsbyImageData
          alt
        }
        link {
          url
        }
      }
    }
  }
`
