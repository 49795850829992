import React, {useState} from 'react'
import ReactPaginate from 'react-paginate'
import Container from './Container'
import FellowCard from './FellowCard'

import {  useFellowsHub } from '../utils/fellowsHubProvider'
import * as s from '../styles/components/ListingArea.module.scss'

const FellowsBySDG = ({lang, btnLabel, projectLabel, prevLabel, nextLabel}) => {

  const { fellows } = useFellowsHub()

  const itemsPerPage = 9
  const [itemOffset, setItemOffset] = useState(0)
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(fellows.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % fellows.length;
    setItemOffset(newOffset);
    const element = document.getElementById("hubFellowCards")
    element.scrollIntoView();
  }


  return (
    <section id="hubFellowCards">
      <Container>
      <div className={s.Grid} style={{justifyContent: 'space-between'}}>
            {
              fellows.slice(itemOffset,endOffset).map((fellow, index)=> (
                <FellowCard
                  key={index}
                  fellow={fellow}
                  project_label={projectLabel}
                  fellow_btn={btnLabel}
                  lang={lang}
                  width="auto"
                />
              ))
            }
          </div>

        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}         
          renderOnZeroPageCount={null}
          previousLabel={prevLabel}
          nextLabel={nextLabel}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </Container>
    </section>
  )
}
export default FellowsBySDG

