import { useLayoutEffect, useState } from 'react';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(()=>{
      setIsMobile(window.innerWidth < 720)
  },[])

  return isMobile;
};

export default useIsMobile;
