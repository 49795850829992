import { GatsbyImage,StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import slugify from '../utils/slugify'
import {Categories} from '../utils/constants'
import * as s from '../styles/components/FellowCard.module.scss'

const FellowCard = ({fellow: fellowData, project_label, fellow_btn, lang, width, showDescription=true, aspectRatio}) => {
  const id = fellowData?.id
  const fellow = fellowData?.data

  const strTrim = (str) => {
    if(!str) return null;
    else if(str[0].length < 260) return str
    else return `${str[0].slice(0,260)}...`
  }
  const cat = fellow?.Which_category_is_most_closely_associated_with_your_project_

  const options = lang === 'fr' ? {
    Project_Name: fellow?.Project_Name_FR || fellow?.Project_Name,
    Project_Description: fellow?.Project_Description_FR || fellow?.Project_Description,
    Project_Category: Categories.fr[cat]
  } : 
  {
    Project_Name: fellow?.Project_Name || fellow?.Project_Name_FR,
    Project_Description: fellow?.Project_Description || fellow?.Project_Description_FR,
    Project_Category: Categories.en[cat]
  }


  return (
    <div className={s.FellowCard}>
      {fellow?.Project_Photo?.localFiles && fellow?.Project_Photo?.localFiles[0].childImageSharp ?  
	      <a href={`/fellows/${slugify(fellow?.Fellow_Name)}`}>
	        <GatsbyImage 
	          image={fellow?.Project_Photo?.localFiles[0].childImageSharp?.gatsbyImageData}
	          alt=""
	          style={{
	            width: '100%',
	            aspectRatio: aspectRatio ?? '393/260'
	          }}
	          imgStyle={{
	            objectFit: 'cover',
	            objectPosition: '50% 40%'
	          }}
	        />
	</a>
        :
	<a href={`/fellows/${slugify(fellow?.Fellow_Name || '')}`}>
	        <StaticImage
	          src='../images/placeholder-img.jpg'
	          alt=""
	        />
	</a>
      }
      <h3>{fellow?.Fellow_Name}</h3>
      <p className={s.project}><strong>{project_label} </strong>{options.Project_Name || "TBD"}</p>
      <p className={s.location}>
        <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.24049 16.3687C5.35073 15.1036 4.5955 14.0485 3.95437 13.1527C2.81124 11.5557 2.03088 10.4654 1.49764 9.59581C1.08786 8.92755 0.843497 8.41916 0.698178 7.93789C0.554079 7.46066 0.5 6.98568 0.5 6.375C0.5 3.13942 3.17708 0.5 6.5 0.5C9.82292 0.5 12.5 3.13942 12.5 6.375C12.5 6.98568 12.4459 7.46066 12.3018 7.93789C12.1565 8.41916 11.9121 8.92755 11.5024 9.59581C10.9691 10.4654 10.1888 11.5557 9.04564 13.1527C8.40449 14.0485 7.64921 15.1037 6.75942 16.3688C6.63579 16.5438 6.36403 16.5437 6.24049 16.3687ZM6.5 9.53125C8.26272 9.53125 9.70833 8.12726 9.70833 6.375C9.70833 4.62273 8.26272 3.21875 6.5 3.21875C4.73728 3.21875 3.29167 4.62273 3.29167 6.375C3.29167 8.12726 4.73728 9.53125 6.5 9.53125Z" stroke="#000"/>
        </svg>
        {fellow?.Community && fellow?.Community[0].data.Name}
      </p>
      <p>
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.7409 7.69912L15.7234 1.82C15.7234 1.71231 15.7023 1.60565 15.6611 1.50615C15.6199 1.40665 15.5595 1.31624 15.4833 1.24009C15.4072 1.16394 15.3168 1.10356 15.2173 1.06236C15.1178 1.02116 15.0111 0.999972 14.9034 1H9.04176L1.235 8.80676C1.16051 8.88121 1.1014 8.96961 1.06109 9.0669C1.02077 9.16419 1 9.26848 1 9.37379C1 9.47911 1.02077 9.58339 1.06109 9.68068C1.1014 9.77797 1.16051 9.86637 1.235 9.94082L7.05945 15.7651C7.13552 15.8412 7.22606 15.9012 7.32582 15.9415C7.42559 15.9819 7.53249 16.0017 7.64009 15.9999C7.74769 15.998 7.85385 15.9745 7.95217 15.9308C8.05049 15.8871 8.139 15.824 8.21241 15.7453L15.7409 7.69912Z" stroke="#333333" strokeMiterlimit="10"/>
          <path d="M13.7391 3.00177C13.8953 3.15793 14.0017 3.35688 14.0448 3.57347C14.0878 3.79007 14.0657 4.01457 13.9812 4.2186C13.8967 4.42263 13.7536 4.59702 13.57 4.71971C13.3864 4.8424 13.1704 4.90788 12.9495 4.90788C12.7287 4.90788 12.5128 4.8424 12.3292 4.71971C12.1456 4.59702 12.0025 4.42263 11.918 4.2186C11.8335 4.01457 11.8114 3.79007 11.8545 3.57347C11.8975 3.35688 12.0038 3.15793 12.16 3.00177C12.3694 2.79241 12.6534 2.6748 12.9495 2.6748C13.2457 2.6748 13.5297 2.79241 13.7391 3.00177Z" stroke="#333333" strokeMiterlimit="10"/>
        </svg>
        {options.Project_Category}
      </p>
	{showDescription && <p>
      {strTrim(options.Project_Description)}
	</p>}
      {fellow?.Bio && <a href={`${lang === 'fr' ? '/fr' : ''}/fellows/${slugify(fellow?.Fellow_Name)}`} className="BtnPrimary">
        {fellow_btn}
      </a>}
    </div>
  )
}
export default FellowCard
