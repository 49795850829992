import React, {useEffect, useState} from 'react'
import FeaturedFellows from './FeaturedFellows'
import FellowsBySDG from './FellowsBySDG'
import FeaturedSDG from './FeaturedSDG'
import SDGFilter from './SDGFilter'
import { graphql } from 'gatsby'


export const HubSection= ({data, fellows, lang}) => {
      
    const {section_title, featured_fellows_label, featured_fellows_description, fellow_btn_label, fellow_project_label, search_btn_label, list_of_sdgs, next_label, prev_label, sdg_filter_title, search_fellows} = data
    const featuredFellows = fellows.filter(({data})=>data.Featured)
    const langId = lang.slice(0,2).toLowerCase()
    return (
    <>
        <FeaturedFellows title={section_title} featuredFellowsLabel={featured_fellows_label} description={featured_fellows_description} btnLabel={search_btn_label} featuredFellows={featuredFellows} lang={langId} searchAllFellowsLink={search_fellows.url} />
        <FeaturedSDG listOfSDGs={list_of_sdgs} fellows={fellows} lang={langId} projectLabel={fellow_project_label} btnLabel={fellow_btn_label}/>
        <SDGFilter listOfSDGs={list_of_sdgs} title={sdg_filter_title} buttonLabel={search_btn_label} searchAllFellowsLink={search_fellows.url}/>
        <FellowsBySDG lang={langId} projectLabel={fellow_project_label} btnLabel={fellow_btn_label} prevLabel={prev_label} nextLabel={next_label} />
    </>
  )
}


export const query = graphql`
  fragment FellowsHubFragment on PrismicFellowsHub {
    data {      
      section_title {
        richText
      }
      featured_fellows_description
      search_fellows {
        url
      }
      featured_fellows_label
      fellow_btn_label
      fellow_project_label
      sdg_filter_title
      prev_label
      next_label,
      search_btn_label,
      list_of_sdgs {
        sdg_id
        sdg_title
        sdg_description
        sdg_icon {
          gatsbyImageData
        }
      }
    }
  }
`