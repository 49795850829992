// extracted by mini-css-extract-plugin
export var BtnPrimary = "FellowCard-module--BtnPrimary--37871";
export var BtnPrimaryAlt = "FellowCard-module--BtnPrimaryAlt--9f86f";
export var Container = "FellowCard-module--Container--37b63";
export var FellowCard = "FellowCard-module--FellowCard--8b14f";
export var FlexWrap = "FellowCard-module--FlexWrap--5078b";
export var H1 = "FellowCard-module--H1--202d6";
export var H2 = "FellowCard-module--H2--a178d";
export var H3 = "FellowCard-module--H3--aae21";
export var H4 = "FellowCard-module--H4--f2119";
export var H5 = "FellowCard-module--H5--88acb";
export var H6 = "FellowCard-module--H6--d5b5e";
export var airTableImg = "FellowCard-module--airTableImg--6718c";
export var circleText = "FellowCard-module--circleText--97958";
export var cssMohuvpDummyInputDummyInput = "FellowCard-module--css-mohuvp-dummyInput-DummyInput--65340";
export var donate = "FellowCard-module--donate--2bc25";
export var fellowNameLink = "FellowCard-module--fellow-name-link--daaaa";
export var imageBox = "FellowCard-module--imageBox--fea76";
export var location = "FellowCard-module--location--8459f";
export var next = "FellowCard-module--next--d7ca6";
export var pagination = "FellowCard-module--pagination--c3947";
export var pagination__link = "FellowCard-module--pagination__link--8691c";
export var pagination__linkActive = "FellowCard-module--pagination__link--active--7688a";
export var pagination__linkDisabled = "FellowCard-module--pagination__link--disabled--6a0ce";
export var previous = "FellowCard-module--previous--56a04";
export var project = "FellowCard-module--project--91150";
export var reactSelect__control = "FellowCard-module--react-select__control--68636";
export var reactSelect__indicator = "FellowCard-module--react-select__indicator--8465c";
export var reactSelect__multiValue = "FellowCard-module--react-select__multi-value--9a566";
export var reactSelect__multiValue__remove = "FellowCard-module--react-select__multi-value__remove--a6ae0";
export var reactSelect__option = "FellowCard-module--react-select__option--254cb";
export var reactSortContainer = "FellowCard-module--react-sort-container--8c7eb";
export var reactSort__control = "FellowCard-module--react-sort__control--7c840";
export var reactSort__indicator = "FellowCard-module--react-sort__indicator--7ee11";
export var reactSort__indicatorSeparator = "FellowCard-module--react-sort__indicator-separator--c3fc2";
export var reactSort__placeholder = "FellowCard-module--react-sort__placeholder--4136c";
export var tagline = "FellowCard-module--tagline--35bc9";
export var textBox = "FellowCard-module--textBox--7afd2";
export var videoContainer = "FellowCard-module--videoContainer--6c6d5";
export var white = "FellowCard-module--white--c13d7";