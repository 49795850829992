import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

import { FlexWrap, Grid, GridItem, image } from '../styles/slices/card_links.module.scss'
import Container from "../components/Container"
import TextBox from '../components/TextBox'


export const CardLinks = ({ slice }) => {
  return (
    <section style={{background: "#20063B"}}>
      <Container>
        <div className={FlexWrap}>
          <TextBox
            width={370}
          >
            <PrismicRichText field={slice.primary.text?.richText}/>
          </TextBox>
          <div className={Grid}>
            {slice.items.map((item,index) => (
              <PrismicLink className={GridItem} href={item.link?.url}>
                <GatsbyImage
                  image={item.image?.gatsbyImageData}
                  alt={item.image?.alt}
                  className={image}
                />
              </PrismicLink>
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyCardLinks on PrismicPageDataBodyCardLinks {
    id
    primary {
      text {
        richText
      }
    }
    items {
      image {
        gatsbyImageData
        alt
        dimensions {
          width
          height
        }
      }
      link {
        url
      }
    }
  }
`
