import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import { FlexWrap } from '../styles/slices/text_image.module.scss'
import { TabLabels, tab } from '../styles/slices/tabs.module.scss'
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs'
import Container from "../components/Container"
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox'

export const TabSection = ({ slice }) => {
  return (
    <section style={{padding:'1px 0 80px'}}>
      <Container>
        <Tabs>
          <TabList className={TabLabels}>
            {slice.items.map((item,index) => (
              <Tab className={tab}>
                {item.tab_header}
              </Tab>
            ))}
          </TabList>
          {slice.items.map((item,index) => (
            <TabPanel>
              <div className={FlexWrap}>
                <TextBox width={673}>
                  <PrismicRichText field={item.text?.richText}/>
                </TextBox>
                <ImageBox
                  image={item.image}
                  borderRadius={20}
                />
              </div>
            </TabPanel>
          ))}
        </Tabs>
        
       
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyTabs on PrismicPageDataBodyTabs {
    id
    items {
      image {
        gatsbyImageData
        alt
        dimensions {
          width
          height
        }
      }
      tab_header
      text {
        richText
      }
    }
  }
`
