// extracted by mini-css-extract-plugin
export var AboutProjectSection = "AboutProjectSection-module--AboutProjectSection--8cba4";
export var BtnPrimary = "AboutProjectSection-module--BtnPrimary--c1c19";
export var BtnPrimaryAlt = "AboutProjectSection-module--BtnPrimaryAlt--f054e";
export var Container = "AboutProjectSection-module--Container--3843b";
export var FlexWrap = "AboutProjectSection-module--FlexWrap--021bd";
export var H1 = "AboutProjectSection-module--H1--ed8ba";
export var H2 = "AboutProjectSection-module--H2--f997c";
export var H3 = "AboutProjectSection-module--H3--44bdd";
export var H4 = "AboutProjectSection-module--H4--3b20b";
export var H5 = "AboutProjectSection-module--H5--4861e";
export var H6 = "AboutProjectSection-module--H6--18b79";
export var airTableImg = "AboutProjectSection-module--airTableImg--deea3";
export var circleText = "AboutProjectSection-module--circleText--79986";
export var communities = "AboutProjectSection-module--communities--2a3b2";
export var cssMohuvpDummyInputDummyInput = "AboutProjectSection-module--css-mohuvp-dummyInput-DummyInput--f65fa";
export var donate = "AboutProjectSection-module--donate--d44f9";
export var imageBox = "AboutProjectSection-module--imageBox--fadb8";
export var imgContainer = "AboutProjectSection-module--imgContainer--1d92f";
export var next = "AboutProjectSection-module--next--d5e4b";
export var pagination = "AboutProjectSection-module--pagination--83166";
export var pagination__link = "AboutProjectSection-module--pagination__link--75b87";
export var pagination__linkActive = "AboutProjectSection-module--pagination__link--active--47ef2";
export var pagination__linkDisabled = "AboutProjectSection-module--pagination__link--disabled--3513c";
export var previous = "AboutProjectSection-module--previous--069fb";
export var reactSelect__control = "AboutProjectSection-module--react-select__control--73011";
export var reactSelect__indicator = "AboutProjectSection-module--react-select__indicator--7ed31";
export var reactSelect__multiValue = "AboutProjectSection-module--react-select__multi-value--4be7f";
export var reactSelect__multiValue__remove = "AboutProjectSection-module--react-select__multi-value__remove--61774";
export var reactSelect__option = "AboutProjectSection-module--react-select__option--a95d0";
export var reactSortContainer = "AboutProjectSection-module--react-sort-container--69699";
export var reactSort__control = "AboutProjectSection-module--react-sort__control--a7d4c";
export var reactSort__indicator = "AboutProjectSection-module--react-sort__indicator--2dc38";
export var reactSort__indicatorSeparator = "AboutProjectSection-module--react-sort__indicator-separator--bf161";
export var reactSort__placeholder = "AboutProjectSection-module--react-sort__placeholder--487e3";
export var tagline = "AboutProjectSection-module--tagline--983cd";
export var textBox = "AboutProjectSection-module--textBox--30f71";
export var un = "AboutProjectSection-module--un--c110a";
export var videoContainer = "AboutProjectSection-module--videoContainer--a4cdc";
export var white = "AboutProjectSection-module--white--3386f";