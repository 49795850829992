import * as React from 'react'
import Container from '../components/Container'
import ReactPlayer from 'react-player'
import TextBox from './TextBox'

const VideoSection = (props) => {
  const {
    title,
    description,
    video,
  } = props
  const [loading, setLoading] = React.useState(false)
  return (
    
    <section className="videoSection">
      <Container>
        <div className="FlexWrap">
          <TextBox width={425}>
            <h2>{title}</h2>
            <p>{description}</p>
          </TextBox>
          <TextBox>
            {
              !loading &&
            
            <div className="videoContainer">
              <ReactPlayer url={video} width={830} style={{maxWidth: '100%'}} onError={() => setLoading(true)}/>
            </div>
            }
          </TextBox>

        </div>
      </Container>
    </section>
  )
}
export default VideoSection;