import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'
import CircleText from '../components/CircleText'


const PageTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicPage || {}
  
  const page = pageContent.data || {}
  const menu = data.prismicMenu || {}
  const common = data.prismicCommon || {}

  const { lang, type, url } = pageContent || {}
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout menu={menu.data} common={common} activeDocMeta={activeDoc} theme={page.theme} title={page.title}>
      <SliceZone slices={page.body} components={components} context={{common:common, lang:lang}}/>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id },lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        theme
        title
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
        
          ...PageDataBodyTextImage
          ...PageDataBodyCardRow
          ...PageDataBodyAlternatingTextImage
          ...PageDataBodyCenterText
          ...PageDataBodyCardLinks
          ...PageDataBodyContactForm
          ...PageDataBodyFaq
          ...PageDataBodyCarousel
          ...PageDataBodyTabs
          ...PageDataBodyContentArea
          ...PageDataBodyPageNav
          ...PageDataBodyAnchorPoint
          ...PageDataBodyCta
          ...PageDataBodyImageStat
          ...PageDataBodyCardlessRow
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    prismicCommon(lang: { eq: $lang }){
      ...CommonFragment
      ...SkipFragment
    }
  }
`

export default withPrismicPreview(PageTemplate);