import { useEffect, useState } from 'react';

const useRandomValue = () => {
const [randomValueOfTheDay, setRandomValueOfTheDay] = useState(null)

useEffect(() => {
    const stored = localStorage.getItem("Date");
    if (!stored) {
        localStorage.setItem("Date", new Date());
        localStorage.setItem("randomNumber", Math.random())
    } else {
        const diff = (new Date() - stored) / (1000 * 3600 * 12);
        if (diff >= 1) { 
        localStorage.setItem("randomNumber", Math.random())
        localStorage.setItem("Date", new Date());
        }
    }
    setRandomValueOfTheDay(localStorage.getItem('randomNumber'))
    },[])
  

  return randomValueOfTheDay;
};

export default useRandomValue;
