import React, {useMemo} from 'react'
import { 
    CarouselProvider,
    Slide, 
    Slider,
    ButtonBack,
    ButtonNext,
  } from 'pure-react-carousel'
import { GatsbyImage} from 'gatsby-plugin-image';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import Container from './Container'
import FellowCard from './FellowCard'
import * as s from '../styles/components/FeaturedSDG.module.scss'
import useIsMobile from '../utils/useIsMobile'
import useRandomValue from '../utils/useRandomValue'


const FeaturedSDG= ({listOfSDGs, fellows, lang, projectLabel, btnLabel}) => {
const isMobile = useIsMobile()
const randomValueOfTheDay = useRandomValue()
const randomSDGIndex = useMemo(() => Math.floor(randomValueOfTheDay * (listOfSDGs.length-1)),[randomValueOfTheDay]);
const randomSDG =  useMemo(() => listOfSDGs.filter((sdg)=>sdg?.sdg_id)[randomSDGIndex], [randomSDGIndex, listOfSDGs]);
const fellowsBySDG = fellows.filter(({data})=> data.SDGs && data.SDGs.includes(randomSDG?.sdg_id))
const shuffledFellows = shuffle(fellowsBySDG)
const fellowsToShow = shuffledFellows.length > 4 ? shuffledFellows.slice(1,5) : shuffledFellows.slice(1)
const randomFeaturedFellow = shuffledFellows[0]

return (
    <section className={s.section}>
      <Container>
        <div className={s.imageContainer}>
            {randomValueOfTheDay ? <GatsbyImage
                image={randomSDG?.sdg_icon?.gatsbyImageData}
                alt={randomSDG?.sdg_icon?.alt || ""}
                style={{borderRadius:'20px'}}
            /> : null}
            <div className={s.titleSection}>
              <h3>{randomSDG?.sdg_title}</h3> 
              <p>{randomSDG?.sdg_description}</p>
          </div>  
        </div>
        <div className={s.projectContainer}>
          <div className={s.featuredCardContainer}> 
              <FellowCard
                  fellow={randomFeaturedFellow}
                  project_label={projectLabel}
                  fellow_btn={btnLabel}
                  lang={lang}
                  showDescription={false}
                  aspectRatio={'600/500'}
              />
          </div>
          {fellowsToShow.length > 0 ? <div className={s.carouselContainer}> 
            <CarouselProvider
              naturalSlideHeight={isMobile ? 160 :120}
              naturalSlideWidth={100}
              totalSlides={fellowsToShow.length}
              visibleSlides={isMobile ? 1 : 2}
              infinite={false}
              step={1}
              orientation={ isMobile ? 'horizontal' : 'vertical'}
              style={{marginBottom: isMobile ? '-200px' : '-350px'}}
              
          > 
             {isMobile && <div className={s.carouselControls}>
              <ButtonBack ><FaChevronCircleLeft  className={s.fa} aria-label="left arrow" /></ButtonBack>
              <ButtonNext><FaChevronCircleRight className={s.fa} aria-label="right arrow" /></ButtonNext>
            </div>}
            <Slider 
              classNameTray="slider-tray"
              classNameTrayWrap="slider-tray-wrap"
            >
              {fellowsToShow.map((fellow,index) => (
                <Slide 
                  index={index} 
                  key={`carousel: ${index}`} 
                  tabIndex={-1} 
                >
                  <div style={{padding:'0 10px'}}>
                  <FellowCard
                    fellow={fellow}
                    project_label={projectLabel}
                    fellow_btn={btnLabel}
                    lang={lang}
                    showDescription={false}
                  />
                  </div>
                </Slide>
              ))}
            </Slider>
            </CarouselProvider>
          </div> : null}
        </div>
      </Container>
    </section>
  )
}
export default FeaturedSDG

function shuffle(array){
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * array.length);
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}