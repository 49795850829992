import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText,PrismicLink } from '@prismicio/react'
import Container from "../components/Container"
import TextBox from '../components/TextBox';

export const CTA = ({ slice }) => {
  const Style = {
    backgroundColor: slice.primary.background_color,
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
  };
  return (
    <section style={Style}>
      <div>
        <Container>
          <TextBox
            width={820}
            tAlign={"center"}
          >
            <PrismicRichText field={slice.primary.copy_rt?.richText}/>
            <PrismicLink href={slice.primary.button_link?.url} className="BtnPrimaryAlt">
              <span>{slice.primary.button_label}</span>
            </PrismicLink>
          </TextBox>
        </Container>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyCta on PrismicPageDataBodyCta {
    id
    primary {
      copy_rt {
        richText
      }
      background_color 
      button_link {
        url
      }
      button_label
    }
  }
`