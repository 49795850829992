import * as React from 'react'
import Container from '../components/Container'
import { FaFacebook, FaInstagram, FaLinkedin, FaGlobe } from 'react-icons/fa'
import TextBox from './TextBox'
import { GatsbyImage } from 'gatsby-plugin-image'

import * as s from '../styles/components/ProjectInfoSection.module.scss'

const ProjectInfoSection = (props) => {
  const {
    name,
    categories,
    description,
    image,
    socials
  } = props
  return (
    <section className={s.ProjectInfoSection}>
      <Container>
        <div className={s.FlexWrap}>        
          <TextBox width={745}>
            <div>
              <h2>{name} <span style={{ display: 'block'}}>{categories}</span></h2>
              
            </div>
            <p>{description}</p>
            {socials.website &&
            <a className={s.website} href={socials.website} target="_blank">
              <FaGlobe/> {socials.website}
            </a>
            }
            <div className={s.socials}>
              {socials.linkedin &&
              <a href={`https://www.linkedIn.com/company/${socials.linkedin}`} aria-label="Linkedin Profile" target="_blank">
                <FaLinkedin/>
              </a>
              }
              {socials.facebook &&
              <a href={`https://www.facebook.com/${socials.facebook}`} aria-label="Facebook Profile" target="_blank">
                <FaFacebook/>
              </a>
              }
              {socials.instagram &&
              <a href={`https://www.instagram.com/${socials.instagram}`} aria-label="Instagram Profile" target="_blank">
                <FaInstagram/>
              </a>
              }
            </div>
          </TextBox>
          {image && <GatsbyImage image={image?.childImageSharp?.gatsbyImageData} alt="" /> }
          
        </div>
      </Container> 
    </section>
  )
}
export default ProjectInfoSection;
