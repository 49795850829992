import React, {useEffect, useState} from 'react'
import FilterArea from './FilterArea'
import FeatureArea from './FeatureArea'
import ListingArea from './ListingArea'
import { graphql } from 'gatsby'


export const DirectorySection = (props) => {
  const {
    data,
    lang,
  } = props

  return (
    <>
      <FilterArea
        lang={lang} 
        title={data.page_title}
        description={data.page_description}
        search_label={data.search_label}
        search_btn={data.search_btn_label}
        filter_label={data.filter_label}
        filter_placeholder={data.filter_placeholder}
        categories_label={data.categories_filter_label}
        communities_label={data.communities_filter_label}
        location_label={data.location_filter_label}
        sdg_label={data.sdg_filter_label}

      />
      <FeatureArea
        title={'Featured Fellows'}
        featured={data.featured_fellows} 
      />

      <ListingArea
        sort_placeholder={data.sort_placeholder}
        alphabetical_label={data.alphabetical_sort_label}
        fellow_project={data.fellow_project_label}
        fellow_btn={data.fellow_btn_label}
        prev={data.prev_label}
        next={data.next_label}
        lang={lang}
      />
    </>
  )
}


export const query = graphql`
  fragment FellowsFragment on PrismicFellowsDirectory {
    data {
      alphabetical_sort_label
      categories_filter_label
      communities_filter_label
      featured_fellows {
        fellow_name
      }
      fellow_btn_label
      fellow_project_label
      filter_label
      filter_placeholder
      location_filter_label
      page_description
      page_title {
        richText
        text
      }
      sdg_filter_label
      search_btn_label
      search_label
      sort_placeholder
      prev_label
      next_label
    }
  }
`