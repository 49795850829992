import * as React from 'react'
import Container from '../components/Container'
import TextBox from './TextBox'
import ImageBox from './ImageBox'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as s from '../styles/components/IMTSection.module.scss'

const IMTSection = (props) => {
  const {
    title,
    description,
    impact,
    mission,
    team,  
  } = props
  return (
    <section className={s.IMTSection}>
      <Container>
        <div className={s.CenterAlign}>
          <TextBox tAlign='center' width={750}>
            <h2>{title}</h2>
            <p>{description}</p>
          </TextBox>
        </div>
        {
          impact.description && (
        <div className='FlexWrap'>
          <GatsbyImage image={impact.image?.childImageSharp?.gatsbyImageData}/>
          <TextBox width={595} style={{marginLeft: 'auto'}}>
            <h3>{impact.header}</h3>
            <p>{impact.description}</p>
          </TextBox>
        </div>
            )
          }
        {
          mission.description && (
        <div className='FlexWrap'>
          <TextBox width={595}>
            <h3>{mission.header}</h3>
            <p>{mission.description}</p>
          </TextBox>
          <GatsbyImage image={mission.image?.childImageSharp?.gatsbyImageData}/>
        </div>
          )
        }
        {
          team.description && (
            <div className='FlexWrap'>
              <GatsbyImage image={team.image?.childImageSharp?.gatsbyImageData}/>
              <TextBox width={595} style={{marginLeft: 'auto'}}>
                <h3>{team.header}</h3>
                <p>{team.description}</p>
                <ul>
                  {team.members?.length > 0 && team.members[0].split(/\r?\n/).map((x,i) => (
                    <li key={i}>{x}</li>
                  ))}
                </ul>
              </TextBox>
            </div>
          )
        }
        
      </Container> 
    </section>
  )
}
export default IMTSection;
