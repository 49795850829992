import React from 'react'

export const customComponents = {
  preformatted: ({text}) => {
    return (
      <div 
        dangerouslySetInnerHTML={{ __html: text }}
      />
    )
  }
}
