// extracted by mini-css-extract-plugin
export var BtnPrimary = "LanguageSwitcher-module--BtnPrimary--2ea3d";
export var BtnPrimaryAlt = "LanguageSwitcher-module--BtnPrimaryAlt--08e7f";
export var Container = "LanguageSwitcher-module--Container--74176";
export var FlexWrap = "LanguageSwitcher-module--FlexWrap--9825a";
export var H1 = "LanguageSwitcher-module--H1--c7396";
export var H2 = "LanguageSwitcher-module--H2--d641c";
export var H3 = "LanguageSwitcher-module--H3--6c9a8";
export var H4 = "LanguageSwitcher-module--H4--1d942";
export var H5 = "LanguageSwitcher-module--H5--01d4b";
export var H6 = "LanguageSwitcher-module--H6--806fe";
export var airTableImg = "LanguageSwitcher-module--airTableImg--49d54";
export var banner = "LanguageSwitcher-module--banner--897d8";
export var circleText = "LanguageSwitcher-module--circleText--89092";
export var cssMohuvpDummyInputDummyInput = "LanguageSwitcher-module--css-mohuvp-dummyInput-DummyInput--330da";
export var donate = "LanguageSwitcher-module--donate--cee29";
export var imageBox = "LanguageSwitcher-module--imageBox--379ea";
export var language_switcher = "LanguageSwitcher-module--language_switcher--7c3ee";
export var next = "LanguageSwitcher-module--next--a2d1b";
export var pagination = "LanguageSwitcher-module--pagination--99d02";
export var pagination__link = "LanguageSwitcher-module--pagination__link--c04e7";
export var pagination__linkActive = "LanguageSwitcher-module--pagination__link--active--530e6";
export var pagination__linkDisabled = "LanguageSwitcher-module--pagination__link--disabled--fb1da";
export var previous = "LanguageSwitcher-module--previous--052a5";
export var reactSelect__control = "LanguageSwitcher-module--react-select__control--808e8";
export var reactSelect__indicator = "LanguageSwitcher-module--react-select__indicator--23814";
export var reactSelect__multiValue = "LanguageSwitcher-module--react-select__multi-value--d3e60";
export var reactSelect__multiValue__remove = "LanguageSwitcher-module--react-select__multi-value__remove--92eac";
export var reactSelect__option = "LanguageSwitcher-module--react-select__option--4291f";
export var reactSortContainer = "LanguageSwitcher-module--react-sort-container--1eb1a";
export var reactSort__control = "LanguageSwitcher-module--react-sort__control--d9b18";
export var reactSort__indicator = "LanguageSwitcher-module--react-sort__indicator--a70d2";
export var reactSort__indicatorSeparator = "LanguageSwitcher-module--react-sort__indicator-separator--621b5";
export var reactSort__placeholder = "LanguageSwitcher-module--react-sort__placeholder--d9182";
export var tagline = "LanguageSwitcher-module--tagline--e9053";
export var textBox = "LanguageSwitcher-module--textBox--e8979";
export var videoContainer = "LanguageSwitcher-module--videoContainer--420db";
export var white = "LanguageSwitcher-module--white--ae743";