import * as React from 'react'
import Container from '../components/Container'
import TextBox from './TextBox'
import { PrismicRichText,PrismicLink } from '@prismicio/react'

const DonateSection = (props) => {
  const {
    text,
    btn_label,
    btn_link,
  } = props

  const Style = {
    backgroundColor: '#20063B',
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  };
  return (
    <section>
      <Container>
        <div style={Style}>
          <TextBox
            width={820}
            tAlign={"center"}
          >
            <PrismicRichText field={text?.richText}/>
            <PrismicLink href={btn_link?.url} className="BtnPrimary">
              <span>{btn_label}</span>
            </PrismicLink>
          </TextBox>
        </div>
      </Container> 
    </section>
  )
}
export default DonateSection;