import * as React from 'react'
import Container from '../components/Container'
import TextBox from '../components/TextBox'
import ImageBox from '../components/ImageBox'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { useWindowWidth } from '@react-hook/window-size'
import { Card, CarouselWrap, btnNav, btnBack, btnNext, CardBtn } from '../styles/slices/carousel.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

function calcWidth(screen, totalSlides, slide_width){
  var maximumWidth = Math.min(totalSlides, 5)
  var minimumWidth = 1
  var adjustedWidth =  Math.floor(screen/slide_width)
  if(adjustedWidth > maximumWidth){
    return maximumWidth
  } else if(adjustedWidth < minimumWidth){
    return minimumWidth
  } else {
    return adjustedWidth
  }
}

const Carousel = (props) => {
  const {
    title,
    images,
    slide_width,
  } = props
  const total_slides = images?.length

  const width = useWindowWidth();
  const [slider_width,setSliderWidth] = React.useState(0)
  
  React.useEffect(() => (
    setSliderWidth(calcWidth(width,total_slides,slide_width))
  ),[width])

  return (
    <section>
      <Container>
        <TextBox width={750}>
          <h2>{title}</h2>
          <p></p>
        </TextBox>
        {
          images &&
        
          <CarouselProvider
            naturalSlideWidth={slide_width + 20}
            
            totalSlides={total_slides}
            visibleSlides={slider_width}
            infinite={true}
            step={1}
            isIntrinsicHeight={true}
            className={CarouselWrap}
          >
            { total_slides > 5 &&
              <div className={btnNav}>
                <ButtonBack className={btnBack} ><FaChevronLeft aria-label="left arrow" /></ButtonBack>
                <ButtonNext className={btnNext} ><FaChevronRight aria-label="right arrow" /></ButtonNext>
              </div>  
            }
            <Slider classNameTray="slider-tray" classNameTrayWrap="slider-tray-wrap" style={{margin: '0 -20px'}}>
              {images?.map((item,index) => (
                <Slide index={index} key={`carousel: ${index}`} tabIndex={-1} classNameHidden="hidden-slide" style={{position: 'relative', aspectRatio: '1/1', overflow: 'hidden', margin: '0 10px', maxWidth: '35%'}}>
                  <GatsbyImage image={item.childImageSharp?.gatsbyImageData}
                  style={{width: '100%', height: '100%'}}/>
                </Slide>
              ))}    
            </Slider>   
          </CarouselProvider>
        }
      </Container> 
    </section>
  )
}
export default Carousel;