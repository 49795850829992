import React from 'react'

const slugify = str => 
  str
    .toLowerCase()
    .trim()
    .replace(/[^A-Za-zÀ-ÖØ-öø-ÿ0-9\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

export default slugify