import React, {useEffect, useState} from 'react'
import Container from '../components/Container'
import TextBox from './TextBox'
import Select from 'react-select'
import { PrismicRichText } from '@prismicio/react'

import * as s from '../styles/components/FilterArea.module.scss'

import {  useFellows } from '../utils/fellowsProvider'
import {Categories, Communities, SDGs} from '../utils/constants'

function findUnique(list){
  const uniqueList = [];
  list.map(item => {
    if(item != "" && item != undefined && uniqueList.indexOf(item) === -1) {
      uniqueList.push(item)
    }
  })
  return uniqueList
}

const FilterArea = (props) => {
  const {
    title,
    description,
    lang,
    search_label,
    search_btn,
    filter_label,
    filter_placeholder,
    categories_label,
    communities_label,
    location_label,
    sdg_label,
  } = props

  const language = lang == 'fr' ? lang : 'en'
  const {fellows, handleSearch, handleFilter} = useFellows()
  
  const loc = findUnique( fellows.map(fellow => fellow.data.Community ? fellow.data.Community[0].data.Name : [] )).sort((a,b) => {
    if(a < b) return -1;
    if(a > b) return 1;
    return 0;
  })

  const catOptions = Object.values(Categories[language]).map((x,i) => {
    return {value: i, label: x}
  })
  const comOptions = Communities[language].map((x,i) => {
    return {value: i, label: x}
  })
  const locOptions = loc.map((x,i) => {
    return {value: i, label: x}
  })
  const sdgOptions = SDGs[language].map((x,i) => {
    return {value: i, label: x}
  })
  
  const [tSearch, setTSearch] = useState()
  const [catFilts, setCatFilts] = useState('')
  const [comFilts, setComFilts] = useState('')
  const [locFilts, setLocFilts] = useState('')
  const [sdgFilts, setSdgFilts] = useState('')

  useEffect(() => {
    const temp = [...catFilts,...comFilts,...locFilts,...sdgFilts]
    handleFilter(temp)
  },[catFilts,comFilts,locFilts,sdgFilts])

  function handleSearchChange(){
   handleSearch(tSearch)
  }
  const onChangeCat = (newValue) => {
    const temp = []
    newValue.map(
      val => {
        temp.push(Categories.en[val.label])
        temp.push(Categories.fr[val.label])
      }
    )
    setCatFilts(temp) 
  }
  const onChangeCom = (newValue) => {
    const temp = []
    newValue.map(
      val => {
        temp.push(Communities.en[val.value])
        temp.push(Communities.fr[val.value])
      }
    )
    setComFilts(temp)
  
  }
  const onChangeLoc = (newValue) => {
    const temp = newValue.map(val => val.label)
    setLocFilts(temp)
  
  }
  const onChangeSdg = (newValue) => {
    const temp = []
    newValue.map(
      val => {
        temp.push(SDGs.en[val.value])
        temp.push(SDGs.fr[val.value])
      }
    )
    setSdgFilts(temp)
  }

  return (
    <section className={s.Filters}>
      <Container>
        <div className="FlexWrap">
          <TextBox width={675}>
            <PrismicRichText field={title?.richText}/>
            <p>{description}</p>
          </TextBox>
          <div className={s.SearchInput}>
            <label>{search_label}</label>
            <div className={s.inputContainer}>
              <input type='text' onChange={(e) => setTSearch(e.target.value)} style={{border: '1px solid black'}}/>
              <button onClick={handleSearchChange} className="BtnPrimary">{search_btn}</button>
            </div>
          </div>
        </div>
        <div className={s.FlexWrap}>
          <span>{filter_label}</span>
          <div>
            <label id="aria-label-category" htmlFor="aria-category-input">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7409 7.69912L15.7234 1.82C15.7234 1.71231 15.7023 1.60565 15.6611 1.50615C15.6199 1.40665 15.5595 1.31624 15.4833 1.24009C15.4072 1.16394 15.3168 1.10356 15.2173 1.06236C15.1178 1.02116 15.0111 0.999972 14.9034 1H9.04176L1.235 8.80676C1.16051 8.88121 1.1014 8.96961 1.06109 9.0669C1.02077 9.16419 1 9.26848 1 9.37379C1 9.47911 1.02077 9.58339 1.06109 9.68068C1.1014 9.77797 1.16051 9.86637 1.235 9.94082L7.05945 15.7651C7.13552 15.8412 7.22606 15.9012 7.32582 15.9415C7.42559 15.9819 7.53249 16.0017 7.64009 15.9999C7.74769 15.998 7.85385 15.9745 7.95217 15.9308C8.05049 15.8871 8.139 15.824 8.21241 15.7453L15.7409 7.69912Z" stroke="#fff" strokeMiterlimit="10"/>
                <path d="M13.7391 3.00177C13.8953 3.15793 14.0017 3.35688 14.0448 3.57347C14.0878 3.79007 14.0657 4.01457 13.9812 4.2186C13.8967 4.42263 13.7536 4.59702 13.57 4.71971C13.3864 4.8424 13.1704 4.90788 12.9495 4.90788C12.7287 4.90788 12.5128 4.8424 12.3292 4.71971C12.1456 4.59702 12.0025 4.42263 11.918 4.2186C11.8335 4.01457 11.8114 3.79007 11.8545 3.57347C11.8975 3.35688 12.0038 3.15793 12.16 3.00177C12.3694 2.79241 12.6534 2.6748 12.9495 2.6748C13.2457 2.6748 13.5297 2.79241 13.7391 3.00177Z" stroke="#fff" strokeMiterlimit="10"/>
              </svg>
              {categories_label}</label>
            <div style={{display: 'flex', flexDirection: 'column'}}>
             
              <Select
                isMulti
                options={catOptions}
                isSearchable={false}
                onChange={onChangeCat}
                aria-labelledby="aria-label-category"
                inputId="aria-category-input"
                isClearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={filter_placeholder}
              />
            </div>
          </div>
          <div>
            <label id="aria-label-community" htmlFor="aria-community-input">
              <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.25 4C4.25 4.85146 3.59797 5.5 2.85 5.5C2.10203 5.5 1.45 4.85146 1.45 4C1.45 3.14854 2.10203 2.5 2.85 2.5C3.59797 2.5 4.25 3.14854 4.25 4ZM17.55 4C17.55 4.85146 16.898 5.5 16.15 5.5C15.402 5.5 14.75 4.85146 14.75 4C14.75 3.14854 15.402 2.5 16.15 2.5C16.898 2.5 17.55 3.14854 17.55 4ZM16.5136 10.5C16.2657 9.32113 15.6286 8.29853 14.7425 7.582C14.8865 7.52858 15.0404 7.5 15.2 7.5H17.1C17.848 7.5 18.5 8.14854 18.5 9V10C18.5 10.3015 18.2755 10.5 18.05 10.5H16.5136ZM12.325 3.5C12.325 5.18271 11.0377 6.5 9.5 6.5C7.96234 6.5 6.675 5.18271 6.675 3.5C6.675 1.81729 7.96234 0.5 9.5 0.5C11.0377 0.5 12.325 1.81729 12.325 3.5ZM9.5 9C10.274 9 10.9987 8.81163 11.6499 8.5H11.78C13.3681 8.5 14.7 9.86417 14.7 11.6V12.5C14.7 13.0765 14.2617 13.5 13.775 13.5H5.225C4.73828 13.5 4.3 13.0765 4.3 12.5V11.6C4.3 9.86417 5.63187 8.5 7.22 8.5H7.3505C8.00323 8.81129 8.72526 9 9.5 9ZM3.8 7.5C3.95961 7.5 4.11352 7.52858 4.25748 7.58199C3.37115 8.29864 2.73391 9.32131 2.48412 10.5H0.95C0.72453 10.5 0.5 10.3015 0.5 10V9C0.5 8.14854 1.15203 7.5 1.9 7.5H3.8Z" stroke="#fff"/>
              </svg>
              {communities_label}
            </label>
            <div style={{display: 'flex', flexDirection: 'column'}}>
           
              <Select
                isMulti
                options={comOptions}
                isSearchable={false}
                onChange={onChangeCom}
                aria-labelledby="aria-label-community"
                inputId="aria-community-input"
                isClearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={filter_placeholder}
              />
            </div>
          </div>
          <div>
          <label id="aria-label-location" htmlFor="aria-location-input">
            <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.24049 16.3687C5.35073 15.1036 4.5955 14.0485 3.95437 13.1527C2.81124 11.5557 2.03088 10.4654 1.49764 9.59581C1.08786 8.92755 0.843497 8.41916 0.698178 7.93789C0.554079 7.46066 0.5 6.98568 0.5 6.375C0.5 3.13942 3.17708 0.5 6.5 0.5C9.82292 0.5 12.5 3.13942 12.5 6.375C12.5 6.98568 12.4459 7.46066 12.3018 7.93789C12.1565 8.41916 11.9121 8.92755 11.5024 9.59581C10.9691 10.4654 10.1888 11.5557 9.04564 13.1527C8.40449 14.0485 7.64921 15.1037 6.75942 16.3688C6.63579 16.5438 6.36403 16.5437 6.24049 16.3687ZM6.5 9.53125C8.26272 9.53125 9.70833 8.12726 9.70833 6.375C9.70833 4.62273 8.26272 3.21875 6.5 3.21875C4.73728 3.21875 3.29167 4.62273 3.29167 6.375C3.29167 8.12726 4.73728 9.53125 6.5 9.53125Z" stroke="#fff"/>
            </svg> 
            {location_label}
          </label>
            <div style={{display: 'flex', flexDirection: 'column'}}>
             
              <Select
                isMulti
                options={locOptions}
                onChange={onChangeLoc}
                aria-labelledby="aria-label-location"
                inputId="aria-location-input"
                isClearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={filter_placeholder}
              />
            </div>
          </div>
          <div>
            <label id="aria-label-sgoals" htmlFor="aria-sgoals-input">
              <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4484 5.35419C17.9604 7.07463 18.744 9.31565 18.6336 11.6034C18.5231 13.8912 17.5272 16.0463 15.8564 17.613C14.1857 19.1798 11.9711 20.0353 9.68099 19.9987C7.39086 19.9621 5.20474 19.0362 3.5849 17.4169C1.96507 15.7976 1.03851 13.6118 1.00117 11.3217C0.963838 9.03157 1.81864 6.81671 3.38483 5.14546C4.95102 3.47421 7.1058 2.4776 9.39352 2.36637C11.6812 2.25515 13.9225 3.03806 15.6435 4.5495" stroke="#FFF" stroke-width="0.75" strokeMiterlimit="10"/>
                <path d="M15.2379 6.56518C16.4286 7.96426 17.0318 9.76977 16.9212 11.6036C16.8105 13.4374 15.9946 15.1574 14.6444 16.4031C13.2941 17.6489 11.5142 18.324 9.6774 18.2869C7.8406 18.2498 6.0894 17.5034 4.79053 16.2042C3.49167 14.9049 2.74591 13.1534 2.70941 11.3166C2.67292 9.47978 3.34852 7.70011 4.59474 6.35025C5.84097 5.00039 7.56113 4.18507 9.39501 4.07504C11.2289 3.965 13.0342 4.56878 14.4329 5.75994" stroke="#FFF" stroke-width="0.75" strokeMiterlimit="10"/>
                <path d="M14.0257 7.77838C14.8967 8.85514 15.3213 10.225 15.2121 11.6056C15.103 12.9862 14.4683 14.2723 13.4389 15.1988C12.4095 16.1253 11.064 16.6215 9.67952 16.5853C8.29508 16.549 6.97732 15.983 5.99788 15.0038C5.01845 14.0247 4.45196 12.7071 4.41523 11.3227C4.37851 9.93828 4.87434 8.59255 5.8005 7.56288C6.72665 6.53321 8.01254 5.89808 9.3931 5.78846C10.7737 5.67884 12.1437 6.10308 13.2207 6.97368" stroke="#FFF" stroke-width="0.75" strokeMiterlimit="10"/>
                <path d="M12.8083 8.99449C13.3581 9.74681 13.6038 10.6789 13.4963 11.6045C13.3888 12.5301 12.9359 13.381 12.2282 13.9872C11.5206 14.5934 10.6102 14.9103 9.6791 14.8745C8.74798 14.8386 7.86464 14.4528 7.20564 13.794C6.54663 13.1352 6.16048 12.252 6.12436 11.3209C6.08825 10.3898 6.40482 9.47933 7.0108 8.77147C7.61679 8.06361 8.46758 7.61049 9.39314 7.50265C10.3187 7.39481 11.2508 7.64018 12.0033 8.18976" stroke="#FFF" stroke-width="0.75" strokeMiterlimit="10"/>
                <path d="M11.3622 10.4411C11.5358 10.8047 11.5753 11.2179 11.4737 11.6078C11.3721 11.9976 11.1359 12.339 10.8069 12.5715C10.4779 12.8041 10.0773 12.9128 9.67584 12.8784C9.27442 12.8441 8.89804 12.6689 8.6133 12.3839C8.32855 12.0989 8.15378 11.7224 8.11984 11.3209C8.0859 10.9195 8.19498 10.5189 8.42783 10.1902C8.66068 9.86137 9.00231 9.62551 9.39227 9.52427C9.78223 9.42303 10.1954 9.46295 10.5588 9.63694" stroke="#FFF" stroke-width="0.75" strokeMiterlimit="10"/>
                <path d="M16.2577 5.17002L16.2402 5.54461L16.6148 5.56212L20.4601 5.74179C21.164 5.77468 21.3727 6.77374 20.6604 7.06716C20.6602 7.06724 20.66 7.06733 20.6598 7.06742L11.3838 10.834C11.1271 10.9244 10.8533 10.8353 10.6619 10.6251C10.4704 10.4149 10.4072 10.1339 10.5211 9.88658L15.1362 1.00229C15.1363 1.00209 15.1364 1.00188 15.1365 1.00168C15.495 0.319903 16.4703 0.620619 16.4374 1.32474L16.2577 5.17002Z" fill="white" stroke="#FFF" stroke-width="0.75"/>
              </svg>
              {sdg_label}
            </label>
            <div style={{display: 'flex', flexDirection: 'column'}}>
             
              <Select
                isMulti
                options={sdgOptions}
                isSearchable={false}
                onChange={onChangeSdg}
                aria-labelledby="aria-label-sgoals"
                inputId="aria-sgoals-input"
                isClearable={false}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={filter_placeholder}
              />
            </div>
          </div>
        
        </div>
      </Container>
    </section>
  )
}
export default FilterArea

