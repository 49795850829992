// extracted by mini-css-extract-plugin
export var BtnPrimary = "FellowInforSection-module--BtnPrimary--5415d";
export var BtnPrimaryAlt = "FellowInforSection-module--BtnPrimaryAlt--203e2";
export var Container = "FellowInforSection-module--Container--ce999";
export var FellowInfoSection = "FellowInforSection-module--FellowInfoSection--65b6f";
export var FlexWrap = "FellowInforSection-module--FlexWrap--13592";
export var H1 = "FellowInforSection-module--H1--83355";
export var H2 = "FellowInforSection-module--H2--7cb2e";
export var H3 = "FellowInforSection-module--H3--6c5e6";
export var H4 = "FellowInforSection-module--H4--e994b";
export var H5 = "FellowInforSection-module--H5--26642";
export var H6 = "FellowInforSection-module--H6--1d7be";
export var airTableImg = "FellowInforSection-module--airTableImg--858e8";
export var circleText = "FellowInforSection-module--circleText--c2eb1";
export var cssMohuvpDummyInputDummyInput = "FellowInforSection-module--css-mohuvp-dummyInput-DummyInput--3a7fe";
export var donate = "FellowInforSection-module--donate--a3160";
export var imageBox = "FellowInforSection-module--imageBox--781e0";
export var location = "FellowInforSection-module--location--2ca32";
export var next = "FellowInforSection-module--next--5d2c0";
export var pagination = "FellowInforSection-module--pagination--c22df";
export var pagination__link = "FellowInforSection-module--pagination__link--813eb";
export var pagination__linkActive = "FellowInforSection-module--pagination__link--active--1b265";
export var pagination__linkDisabled = "FellowInforSection-module--pagination__link--disabled--f2535";
export var previous = "FellowInforSection-module--previous--ec409";
export var reactSelect__control = "FellowInforSection-module--react-select__control--28998";
export var reactSelect__indicator = "FellowInforSection-module--react-select__indicator--23a52";
export var reactSelect__multiValue = "FellowInforSection-module--react-select__multi-value--e6f47";
export var reactSelect__multiValue__remove = "FellowInforSection-module--react-select__multi-value__remove--8759c";
export var reactSelect__option = "FellowInforSection-module--react-select__option--74f6b";
export var reactSortContainer = "FellowInforSection-module--react-sort-container--e92f6";
export var reactSort__control = "FellowInforSection-module--react-sort__control--9d2c3";
export var reactSort__indicator = "FellowInforSection-module--react-sort__indicator--fe0b2";
export var reactSort__indicatorSeparator = "FellowInforSection-module--react-sort__indicator-separator--10aa1";
export var reactSort__placeholder = "FellowInforSection-module--react-sort__placeholder--fc331";
export var social = "FellowInforSection-module--social--9dc24";
export var tagline = "FellowInforSection-module--tagline--0af29";
export var textBox = "FellowInforSection-module--textBox--e0172";
export var videoContainer = "FellowInforSection-module--videoContainer--b3495";
export var white = "FellowInforSection-module--white--d4ff3";