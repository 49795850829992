import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText,PrismicLink } from '@prismicio/react';
import Container from "../components/Container";
import TextBox from '../components/TextBox';
import ImageBox from '../components/ImageBox';
import { FlexWrap, FlexGrid, ImageStatContainer, statItem } from '../styles/slices/text_image.module.scss'

export const ImageStat = ({ slice }) => {
  const Text = ({}) => (
    <TextBox width={610}>
      <PrismicRichText field={slice.primary.header_rt?.richText}/>
      <div className={FlexGrid}>
        {slice.items.map((item,index) => (
            <div className={statItem}>
              <h3>{item.stat}</h3>
              <p>{item.stat_description}</p>
            </div>
          ))}
        </div>
    </TextBox>
  );
  const Image = ({}) =>{ 
    return(
      <ImageBox
        image={slice.primary.image}
        borderRadius={20}
      />
    )
  };

  return (
    <section className={ImageStatContainer}>
      <Container>
        <div className={FlexWrap}>
          {
            slice.primary.image_side ? (  
              <>
                <Text/>
                <Image/>
              </>  
            ) : (
              <>
                <Image/>
                <Text/>
              </> 
            )
          }

        </div>
        
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment PageDataBodyImageStat on PrismicPageDataBodyImageStat {
    id
    primary {
      image {
        gatsbyImageData
        alt
        dimensions {
          width
          height
        }
      }
      image_side
      header_rt {
        richText
      }
    }
    items {
      stat
      stat_description
    }
  }
`
